import axios from "axios";

const getBaseUrl = () => {
  const { hostname, port } = window.location;

  if (
    port === "3001" ||
    port === "3002" ||
    hostname === "admin.winlifenet.com" ||
    hostname === "admin-devenv.winlifenet.com"
  ) {
    return "admin/account/login";
  } else if (
    port === "3000" ||
    hostname === "partner.winlifenet.com" ||
    hostname === "partner-devenv.winlifenet.com" ||
    hostname === "dev-partner.winlifenet.com"
  ) {
    return "client/account/login";
  } else {
    throw new Error("Unsupported port or domain");
  }
};

const loginUrl = getBaseUrl();

export function postLoginData(userData) {
  return axios.post(loginUrl, {
    ...userData,
  });
}

const forgetPasswordUrl = "forget/password";

export function postForgetPassword(userData) {
  return axios.post(`${forgetPasswordUrl}/send-link`, {
    ...userData,
  });
}

export function postValidateResetPasswordToken(userData) {
  return axios.post(`${forgetPasswordUrl}/validate-token`, {
    ...userData,
  });
}

export function postResetPassword(userData) {
  return axios.post(`${forgetPasswordUrl}/change`, {
    ...userData,
  });
}

export function logout() {
  return axios.get(`logout`);
}
