import React from "react";
import OrgChart from "@dabeng/react-orgchart";
import "@dabeng/react-orgchart/dist/ChartContainer.css";
import "@dabeng/react-orgchart/dist/ChartNode.css";
import { Link } from "react-router-dom";
import clsx from "clsx";

const OrgChartComponent = ({ datasource, setDatasource, ...props }) => {
  const NodeTemplate = ({ nodeData }) => {
    const {
      id,
      name,
      profile_pic,
      is_pv_active,
      total_R,
      total_L,
      referral_code,
    } = nodeData;
    return (
      <div
        className={clsx(
          "oc-node tw-flex tw-min-w-[70px] tw-justify-center tw-items- tw-shrink-0 tw-min-h-[100px]",
          {
            "!tw-bg-green-400": !!is_pv_active,
          }
        )}
      >
        {id ? (
          <>
            <Link
              className="tw-flex tw-justify-center tw-relative"
              to={`/tree/${id}`}
            >
              {profile_pic ? (
                <img src={profile_pic} alt={name} />
              ) : (
                <img src="/imgs/partner.png" alt="Default Profile" />
              )}
              {/* {!!is_pv_active && (
                <div className="tw-absolute badge tw-bg-green-400">Active</div>
              )} */}
            </Link>

            <div className="oc-title">{name}</div>
            <div className="oc-content">
              <div className="code">Code: {referral_code}</div>
              <div className="stats">
                L: {total_L}, R: {total_R}
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              src="/imgs/empty.png"
              alt="Empty Profile"
              className="!tw-w-full tw-object-contain tw-flex "
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div id="chart-container" className="orgchart-container">
      {datasource && (
        <div className="orgchart-wrapper">
          <div className="tw-w-full tw-flex ">
            <div className="tw-shadow-sm tw-inline-flex tw-p-4">
              <div className="tw-flex tw-flex-shrink-0 tw-gap-4">
                <div className="tw-bg-green-400 tw-w-10 tw-h-4 tw-shrink-0"></div>
                <span>PV Active</span>
              </div>
            </div>
          </div>
          <OrgChart datasource={datasource} NodeTemplate={NodeTemplate} />
        </div>
      )}
    </div>
  );
};

export default OrgChartComponent;
