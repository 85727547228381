import React from "react";
import { useNavigate } from "react-router";

const BackBtn = ({ link, text }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(link);
  };
  return (
    <div className="">
      <button onClick={navigateTo} className="primary-btn tw-capitalize">
        {text}
      </button>
    </div>
  );
};

export default BackBtn;
