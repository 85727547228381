import React, { useState } from "react";
import Modal from "react-modal";

const PaymentDocument = ({ documentUrl }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!documentUrl) {
    return null; // or you can return a placeholder or a message
  }

  const extension = documentUrl.split(".").pop().toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  if (imageExtensions.includes(extension)) {
    return (
      <>
        <img
          src={documentUrl}
          className="tw-cursor-pointer"
          style={{ maxWidth: 300, objectFit: "contain" }}
          alt="Payment Document"
          onClick={openModal}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Zoom Modal"
          className="tw-flex tw-justify-center tw-items-center tw-bg-black tw-bg-opacity-75 tw-fixed tw-inset-0"
          overlayClassName="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-75"
        >
          <img
            src={documentUrl}
            className="tw-w-auto tw-max-h-screen tw-object-contain"
            alt="Zoomed Payment Document"
          />
          <button
            onClick={closeModal}
            className="tw-absolute tw-top-4 tw-right-4 tw-text-white tw-text-2xl"
          >
            &times;
          </button>
        </Modal>
      </>
    );
  } else {
    return (
      <a href={documentUrl} download target="_blank">
        <button className="tw-download-btn">Download Document</button>
      </a>
    );
  }
};

export default PaymentDocument;
