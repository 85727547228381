import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import NotLoggedInLayout from './NotLoggedInLayout';
import ForgetPassword from '../Pages/Auth/ForgetPassword';
import ResetPassword from '../Pages/Auth/ResetPassword';


const Login = lazy(() => import('../Pages/Auth/Login'));

const NotAuthinticatedRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/auth" element={<NotLoggedInLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="forget-password" element={<ForgetPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="" element={<Navigate to="login" />} /> {/* Redirect /auth to /auth/login */}
                    <Route path="*" element={<Navigate to="login" />} /> {/* Redirect any other path under /auth to /auth/login */}
                </Route>
                <Route path="*" element={<Navigate to="/auth" />} /> {/* Redirect any unmatched route to /auth */}
            </Routes>
        </>
    )
}

export default NotAuthinticatedRoutes
