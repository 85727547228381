export function absoluteUrl(relativeUrl) {
  return process.env.PUBLIC_URL + "/" + relativeUrl;
}

export const formatKey = (key) =>
  key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const renderStarIcon = (index, ratingValue) => {
  if (index <= ratingValue - 1) {
    return 'star'; // Fully filled star
  } else if (index < ratingValue && ratingValue % 1 !== 0) {
    return 'star_half'; // Half-filled star
  } else {
    return 'star_border'; // Empty star
  }
};

export function splitTime(input) {
  if (input) {

    const hours = parseInt(input.split('H')[0]);
    const mins = parseInt(input.split(':')[1].split('M')[0]);
    return { hours, mins };
  }
}
