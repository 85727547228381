import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../Redux/Auth/AuthActions";

// Initial values for the form
const initialValues = {
  course_id: "",
  confirmSelection: false,
};

// Validation schema for the form
const validationSchema = Yup.object().shape({
  course_id: Yup.string().required("You must select one course"),
  confirmSelection: Yup.boolean().oneOf(
    [true],
    "You must confirm your selection"
  ),
});

const AddNewCourse = () => {
  const [courses, setCourses] = useState([]);
  const dispatch = useDispatch();
  const walletBalance = useSelector((state) => state.Auth.walletBalance);

  const fetchCourses = async () => {
    try {
      const response = await axios.get("/client/course/all");
      setCourses(response.data.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
    dispatch(fetchWalletBalance());
  }, [dispatch]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append("course_id", values.course_id);

    try {
      await axios.post("/client/course/purchase", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({
        title: "Success",
        text: "Course has been purchased successfully",
        icon: "success",
      });
      dispatch(fetchWalletBalance()); // Refetch wallet balance after purchase
      fetchCourses();
      resetForm();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "There was an error purchasing the course",
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">Add New Course</h4>
        <h5 className="card-title mb-0">
          Current Wallet:{" "}
          <span className="text-muted">{walletBalance} e.m</span>
        </h5>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Course</th>
                        {/* <th>Type</th> */}
                        <th>e-money</th>
                        <th>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.length > 0 ? (
                        courses.map((course, index) => (
                          <tr key={course.id}>
                            <td>{index + 1}</td>
                            <td className="d-flex align-items-center tw-gap-4">
                              <img
                                src={course.image || "/imgs/notFound.jpg"}
                                alt={course.course_name_en}
                                className="tw-rounded-full tw-w-10 tw-h-10 tw-shrink-0"
                              />
                              {course.course_name_en}
                            </td>
                            {/* <td>
                              {course.weight <= 100 ? "Online" : "Offline"}
                            </td> */}
                            <td>{course.e_money} e.m</td>
                            <td>
                              {course.e_money <= walletBalance ? (
                                <div className="tw-w-full tw-flex">
                                  <div className=" tw-bg-[#F4F4F4] tw-p-2 tw-shadow-sm tw-rounded-full tw-w-10 tw-h-10 tw-shrink-0 tw-flex tw-flex-col tw-justify-center tw-items-center">
                                    <input
                                      type="radio"
                                      className="tw-flex tw-justify-start tw-w-6"
                                      name="course_id"
                                      value={course.id}
                                      id={`course_${course.id}`}
                                      onChange={() =>
                                        setFieldValue("course_id", course.id)
                                      }
                                    />
                                  </div>
                                  <label
                                    className="form-check-label"
                                    htmlFor={`course_${course.id}`}
                                  ></label>
                                </div>
                              ) : (
                                <span className="text-danger">
                                  insufficient e-money
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No courses available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <ErrorMessage
                    name="course_id"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-check mb-3 tw-flex tw-items-end tw-w-full">
                <input
                  type="checkbox"
                  className={`form-check-input tw-h-5 tw-w-5 ${
                    touched.confirmSelection && errors.confirmSelection
                      ? "is-invalid"
                      : ""
                  }`}
                  id="confirmCheckbox"
                  name="confirmSelection"
                  checked={values.confirmSelection}
                  onChange={(e) =>
                    setFieldValue("confirmSelection", e.target.checked)
                  }
                />
                <label
                  className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                  htmlFor="confirmCheckbox"
                >
                  I confirm that the selected course is preferred selection, and
                  I am aware that I can't change this selection later
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={!values.course_id}
              >
                Buy
              </button>
              {!values.course_id && (
                <div className="text-danger mt-2">
                  You must select one course
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddNewCourse;
