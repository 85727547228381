import axios from "axios";

export const adminIndicatorsApi = () => {
  return axios.get(`/admin/home/indicators`);
};
export const partnerAchievementsApi = () => {
  return axios.get(`/client/home/my-achievements`);
};
export const totalAdminRequestsApi = () => {
  return axios.get(`/admin/home/get-total-requests-number`);
};
export const pendingRequestsApi = () => {
  return axios.get(`/admin/payment/requests/sidebar/pending`);
};
export function partnerEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/client/request/list`, {
    params: { search: searchTerm, page },
  });
}
export const partnerProfileApi = (id) => {
  return axios.get(`/admin/partners-list/profile/${id}`);
};
export const viewPaymentDetailsApi = (id) => {
  return axios.get(`/admin/payment/requests/details/${id}`);
};
export const viewAdminCoursesApi = () => {
  return axios.get(`/admin/reports/courses/view`);
};

// Taswia
export const taswiaListApi = (
  searchTerm = "",
  startDate = "",
  endDate = "",
  page = 1
) => {
  return axios.get("/admin/taswia/list", {
    params: {
      SelectionDateStart: startDate,
      SelectionDateEnd: endDate,
      search: searchTerm,
      page: page,
    },
  });
};

export const canCreateTaswiaApi = () => {
  return axios.get("/admin/taswia/can-create");
};

export const lastTaswiaEndDateApi = () => {
  return axios.get("/admin/taswia/last-taswia-end-date");
};

export const createTaswiaApi = (endDate, confirm) => {
  return axios.post("/admin/taswia/create", null, {
    params: {
      end_date: endDate,
      confirm: confirm,
    },
  });
};

export const listUnsettledClientsApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/unsettled-clients/${taswiaId}`, {
    params: { page },
  });
};
export const listSettledClientsApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/settled-clients/${taswiaId}`, {
    params: { page },
  });
};

export const viewClientToSettleApi = (taswiaId, partnerId) => {
  return axios.get(
    `/admin/taswia/view-client-to-settle/${taswiaId}/${partnerId}`
  );
};

export const viewClientCurrentCollectionApi = (
  taswiaId,
  partnerId,
  page = 1,
  pageParam = "page"
) => {
  return axios.get(
    `/admin/taswia/client-current-collection/${taswiaId}/${partnerId}`,
    {
      params: { [pageParam]: page },
    }
  );
};

export const confirmTaswiaApi = (taswiaId, partnerId) => {
  return axios.get(`/admin/taswia/confirm/${taswiaId}/${partnerId}`);
};

export const viewSettlementSummaryApi = (taswiaId) => {
  return axios.get(`/admin/taswia/settlement-summery/${taswiaId}`);
};

export const endTaswiaApi = (id, notes, confirm) => {
  return axios.post(`/admin/taswia/end/${id}`, {
    notes: notes,
    confirm: confirm,
  });
};

export const viewPartnerSettlementDetailsApi = (taswiaId, partnerId) => {
  return axios.get(
    `/admin/taswia/partner-settlement-details/${taswiaId}/${partnerId}`
  );
};

export const viewSettlementHistoryApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/finished-taswia-history/${taswiaId}`, {
    params: { page },
  });
};

// Replace eMoney

export const earningWalletApi = (id) => {
  return axios.get(`/admin/replace/emoney/earning-wallet/${id}`);
};

export function pendingEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/pending`, {
    params: { search: searchTerm, page },
  });
}

export function acceptedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/accepted`, {
    params: { search: searchTerm, page },
  });
}

export function rejectedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/rejected`, {
    params: { search: searchTerm, page },
  });
}

export const viewEMoneyDetailsApi = (id) => {
  return axios.get(`/admin/replace/emoney/details/${id}`);
};

export const acceptEMoneyRequestApi = (id) => {
  return axios.post(`/admin/replace/emoney/accept/${id}`);
};

export const rejectEMoneyRequestApi = (id) => {
  return axios.post(`/admin/replace/emoney/reject/${id}`);
};

// partner replace emoney

export const replaceEmoneyRequestApi = (formData) => {
  return axios.post(`/client/replace/emoney`, formData);
};

export const viewEarningWalletApi = () => {
  return axios.get(`/client/replace/emoney/earning-wallet`);
};

export function viewAllReplacementReqsApi(searchTerm = "", page = 1) {
  return axios.get(`/client/replace/emoney/list`, {
    params: { search: searchTerm, page },
  });
}
