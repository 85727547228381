import React from 'react'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const SidebarAndContent = () => {
    return (
        <>
            {/* <!-- Header Layout Content --> */}
            <div className="mdk-header-layout__content" style={{ paddingTop: 72 }}>
                <div data-push="" data-responsive-width="992px" className="mdk-drawer-layout js-mdk-drawer-layout">

                    <Sidebar />
                    <div className="mdk-drawer-layout__content page d-flex flex-column">
                        <div className="px-4 tw-mt-2">
                            <Outlet />
                        </div>
                        <Footer />
                    </div>

                </div>



            </div>
            {/* <!-- // END Header Layout Content --> */}

        </>
    )
}

export default SidebarAndContent
