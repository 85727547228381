import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partnersData: [],
  isLoading: false,
  error: "",
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0,
  },
};

export const PartnersSlice = createSlice({
  name: "Partners",
  initialState,
  reducers: {
    setPartnersData: (state, action) => {
      state.partnersData = action.payload.data;
      state.pagination = action.payload.paginator;
    },
    clearPartnersData: (state) => {
      state.partnersData = [];
      state.pagination = {
        current_page: 1,
        last_page: 1,
        per_page: 15,
        total: 0,
      };
    },
    catchError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setPartnersData, clearPartnersData, catchError, setLoading } =
  PartnersSlice.actions;
export default PartnersSlice.reducer;
