import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../Components/Tabs";
import DataTable from "react-data-table-component";
import { createColumns } from "../../helpers";
import { fetchRequests } from "../../Redux/Requests/RequestsActions";
import { DATATABLE_CUSTOM_STYLES } from "../../constants/datatableStyles";
import { Link } from "react-router-dom";
import {
  ADMIN_DATA_TABS,
  MODERATOR_DATA_TABS,
  USER_TYPES,
} from "../../constants/globals";
import CustomPagination from "../../Components/CustomPagination";

const RequestsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(location.search).get("tab") || "pending"
  );
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );

  const { requestsData, isLoading, pagination } = useSelector(
    (state) => state.Requests
  );
  const { loggedInUser } = useSelector((state) => state.Auth);
  const currentUserType = loggedInUser?.data?.model?.user_type_id;

  useEffect(() => {
    dispatch(fetchRequests(currentTab, searchTerm, currentPage));
  }, [currentTab, searchTerm, currentPage, dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tab", currentTab);
    if (searchTerm) params.set("search", searchTerm);
    params.set("page", currentPage);
    navigate({ search: params.toString() });
  }, [currentTab, searchTerm, currentPage, navigate]);

  useEffect(() => {
    if (currentUserType === USER_TYPES.MODERATOR) {
      setCurrentTab("accepted");
    }
  }, [currentUserType]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    setCurrentPage(1); // Reset to first page on tab change
  };

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterAndSortData = () => {
    return requestsData;
  };

  const hiddenColumns = ["id", "client_id"]; // Add column keys you want to hide
  const customColumns =
    currentTab === "accepted" || currentTab === "rejected"
      ? [
        {
          name: "Request's Details",
          selector: (row) => row.name + " - custom",
          ui: (row) => (
            <Link
              className="tw-text-primary"
              to={`/payment-details/${row.id}`}
            >
              Details
            </Link>
          ),
        },
      ]
      : currentTab === "pending"
        ? [
          {
            name: "Action",
            selector: (row) => row.name + " - pending",
            ui: (row) => (
              <Link
                to={`/request-details/${row.id}`}
                className="tw-text-primary tw-font-semibold"
              >
                View Details {row.Step_number}
              </Link>
            ),
          },
        ]
        : [];

  const columns = useMemo(() => {
    return createColumns(requestsData, customColumns, hiddenColumns);
  }, [requestsData]);

  const TabsList =
    currentUserType === USER_TYPES.ADMIN
      ? ADMIN_DATA_TABS
      : MODERATOR_DATA_TABS;

  return (
    <>
      <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Requests</h4>

      <div className="bg-white flex flex-col mt-4">
        <Tabs
          options={TabsList}
          onTabClick={handleTabClick}
          currentTab={currentTab}
        />

        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <div className="relative">
            <input
              type="text"
              onChange={handleFilter}
              value={searchTerm}
              className="border border-blue-300 rounded-lg p-2 mb-2 col-md-12"
              placeholder="Search by name"
            />
          </div>
          <DataTable
            columns={columns}
            data={filterAndSortData()}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestsList;
