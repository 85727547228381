import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Tabs from "../../Components/Tabs";
import DataTable from "react-data-table-component";
import { createColumns } from "../../helpers";
import { fetchAdminPartners } from "../../Redux/AdminPartners/AdminPartnersActions";
import { DATATABLE_CUSTOM_STYLES } from "../../constants/datatableStyles";
import { ADMIN_PARTNERS_DATA_TABS, USER_TYPES } from "../../constants/globals";
import { absoluteUrl } from "../../_helper";
import CustomPagination from "../../Components/CustomPagination";

const AdminPartnersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(location.search).get("tab") || "all"
  );
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [sortKey, setSortKey] = useState(
    new URLSearchParams(location.search).get("sortKey") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    new URLSearchParams(location.search).get("sortDirection") || ""
  );

  const { adminPartnersData, isLoading, pagination } = useSelector(
    (state) => state.AdminPartners
  );
  const { loggedInUser } = useSelector((state) => state.Auth);
  const currentUserType = loggedInUser?.data?.model?.user_type_id;

  useEffect(() => {
    dispatch(
      fetchAdminPartners(
        currentTab,
        searchTerm,
        currentPage,
        sortKey,
        sortDirection
      )
    );
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tab", currentTab);
    if (searchTerm) params.set("search", searchTerm);
    params.set("page", currentPage);
    if (sortKey) params.set("sortKey", sortKey);
    if (sortDirection) params.set("sortDirection", sortDirection);
    navigate({ search: params.toString() });
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, navigate]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    setCurrentPage(1); // Reset to first page on tab change
  };

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column, direction) => {
    setSortKey(column.sortKey);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const filterAndSortData = () => {
    return adminPartnersData;
  };

  const hiddenColumns = ["id", "action", "must_verify_email", "client_id"]; // Add column keys you want to hide

  const customColumns =
    currentTab === "all" ||
    currentTab === "pv-active" ||
    currentTab === "direct"
      ? [
          {
            name: "Partner's Tree",
            selector: (row) => row.name + " - custom",
            ui: (row) => (
              <Link to={`/tree/${row.id}`}>
                <img
                  src={absoluteUrl("imgs/tree.png")}
                  style={{ width: 40 }}
                  alt=""
                />
              </Link>
            ),
          },
          ...(currentTab === "all"
            ? [
                {
                  name: "Actions",
                  selector: (row) => row.name + " - custom",
                  ui: (row) => (
                    <div className="tw-flex tw-gap-1">
                      <Link
                        className="tw-text-primary"
                        to={`/profile/${row.id}`}
                      >
                        Details
                      </Link>
                      {currentUserType !== USER_TYPES.PARTNER && (
                        <>
                          {" | "}
                          <Link
                            className="tw-text-primary"
                            to={`/update-profile/${row.id}`}
                          >
                            Edit
                          </Link>
                        </>
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]
      : [];

  const columns = useMemo(() => {
    return createColumns(adminPartnersData, customColumns, hiddenColumns, true);
  }, [adminPartnersData]);

  return (
    <div className="bg-white flex flex-col mt-4">
      <Tabs
        options={ADMIN_PARTNERS_DATA_TABS}
        onTabClick={handleTabClick}
        currentTab={currentTab}
      />

      <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
        <div className="relative">
          <input
            type="text"
            onChange={handleFilter}
            value={searchTerm}
            className="border border-blue-300 rounded-lg p-2 mb-2 col-md-12"
            placeholder="Search by name"
          />
        </div>
        <DataTable
          columns={columns}
          data={filterAndSortData()}
          customStyles={DATATABLE_CUSTOM_STYLES}
          progressPending={isLoading}
          onSort={handleSortChange}
          sortServer
        />
        <div className="flex justify-center mt-4">
          <CustomPagination
            totalPages={pagination?.last_page}
            currentPage={pagination?.current_page || 1}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPartnersList;
