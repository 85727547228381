import * as requestFromServer from "./AuthCrud";
import { UISlice } from "../UISlice";
import { AuthSlice, clearLoggedInUser, setWalletBalance } from "./AuthSlice";
import { getMyWalletBalance } from "../AddOrEditPartner/AddOrEditPartnerCrud";

const { actions: AuthActions } = AuthSlice;
const { actions: UIActions } = UISlice;

//LoginAction
export const LoginAction = (userInfo, onErrorHandler) => (dispatch) => {
  dispatch(UIActions.openLoader());

  return requestFromServer
    .postLoginData({ ...userInfo })
    .then((response) => {
      if (response.status)
        dispatch(AuthActions.setLoggedInUserInfo(response.data));
    })
    .catch((error) => {
      onErrorHandler(error?.response?.data?.message);
      dispatch(AuthActions?.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

// Fist Step of forget password
export const ForgetPasswordAction =
  (userInfo, onSuccessHandler, onErrorHandler) => (dispatch) => {
    dispatch(UIActions.openLoader());

    return requestFromServer
      .postForgetPassword(userInfo)
      .then((response) => {
        onSuccessHandler();
      })
      .catch((error) => {
        onErrorHandler(error?.response?.data?.message);
        dispatch(AuthActions?.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const validateResetPasswordToken =
  (userInfo, onSuccessHandler, onErrorHandler) => (dispatch) => {
    dispatch(UIActions.openLoader());

    return requestFromServer
      .postValidateResetPasswordToken(userInfo)
      .then((response) => {
        if (response?.data?.message === "success") {
          onSuccessHandler();
          dispatch(
            // userInfo : email , type(client or admin), token
            AuthActions.setForgetPasswordInfo({ ...userInfo })
          );
        }
      })
      .catch((error) => {
        onErrorHandler(error?.response?.data?.message);
        dispatch(AuthActions?.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const ResettPasswordAction =
  (userInfo, onSuccessHandler, onErrorHandler) => (dispatch, getState) => {
    const authState = getState().Auth; // Assuming 'auth' is the key for AuthSlice in your root reducer
    const { forgetPasswordInfo } = authState;

    dispatch(UIActions.openLoader());

    return requestFromServer
      .postResetPassword({
        ...forgetPasswordInfo,
        // forgetPasswordInfo >> email , type , token
        ...userInfo,
        // ...userInfo >>password , password_confirmation
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          onSuccessHandler();
        }
      })
      .catch((error) => {
        onErrorHandler(error?.response?.data?.message);

        dispatch(AuthActions?.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const logoutAction = () => (dispatch, getState) => {
  return requestFromServer
    .logout()
    .then(() => {
      dispatch(clearLoggedInUser());
    })
    .catch((error) => {})
    .finally(() => {});
};

export const fetchWalletBalance = () => async (dispatch) => {
  try {
    const response = await getMyWalletBalance();
    const balance = response.data.data.balance;
    dispatch(setWalletBalance(balance));
  } catch (error) {
    console.error("Error fetching wallet balance:", error);
  }
};
