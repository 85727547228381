import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const Loader = ({ delay = 300, minDisplayTime = 500 }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartTime(Date.now());
      setShowLoader(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  useEffect(() => {
    if (!showLoader || !startTime) return;

    const minDisplayTimer = setTimeout(() => {
      setShowLoader(false);
    }, minDisplayTime);

    return () => clearTimeout(minDisplayTimer);
  }, [showLoader, startTime, minDisplayTime]);

  if (!showLoader) return null;

  return (
    <Backdrop
      style={{ zIndex: 9999, color: "#BDBDBD" }}
      open={true}
      onClick={(e) => e.preventDefault()}
    >
      <div
        className="fs-16"
        style={{
          textTransform: "initial",
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        <br />
        <br />
        <CircularProgress size="5rem" color="inherit" />
      </div>
    </Backdrop>
  );
};

export default Loader;
