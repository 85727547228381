import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { viewAdminCoursesApi } from "../../apis";

const Reports = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCourses = async () => {
    try {
      const response = await viewAdminCoursesApi();
      setCourses(response.data.data);
    } catch (error) {
      setError("Failed to fetch courses");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">Courses List</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Course</th>
                  <th>Is Active</th>
                  <th>Partners List</th>
                </tr>
              </thead>
              <tbody>
                {courses.length > 0 ? (
                  courses.map((course, index) => (
                    <tr key={course.id}>
                      <td>{index + 1}</td>
                      <td className="d-flex align-items-center tw-gap-4">
                        <img
                          src={course.image || "/imgs/notFound.jpg"}
                          alt={course.course_name_en}
                          className="tw-rounded-full tw-w-10 tw-h-10 tw-shrink-0"
                        />
                        {course.course_name_en}
                      </td>
                      <td>{course.is_active}</td>
                      <td>
                        <Link
                          className="tw-text-primary tw-font-semibold"
                          to={`/reports/${course.id}`}
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No courses available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
