import React from "react";
import { AdminIcon } from "./Svgs/AdminIcon";
import { CalenderIcon } from "./Svgs/CalenderIcon";
import { format } from "date-fns";
import moment from "moment/moment";

const AnnouncementCard = ({
  name,
  date,
  title,
  description,
  link,
  link_btn_text,
}) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");

  return (
    <div className="card w-full bg-white shadow-md overflow-hidden">
      <div className="tw-border-l-4 border-left-primary py-2">
        <div className="d-flex ml-4 mb-3">
          {!!name && (
            <div className="d-flex align-items-center">
              <AdminIcon className="icon-primary" />
              <div className="ml-2">{name}</div>
            </div>
          )}
          {!!date && (
            <div className="d-flex align-items-center ml-3">
              <CalenderIcon className="icon-primary" />
              <div className="ml-2">{formattedDate}</div>
            </div>
          )}

          {link && (
            <div className="d-flex align-items-center ml-3">
              <span className="fa fa-link icon-primary text-primary"></span>
              <a
                href={link}
                className="ml-2 text-primary"
                style={{ textDecoration: "underline", color: "#000" }}
              >
                {link_btn_text}
              </a>
            </div>
          )}
        </div>
        <div className="ml-4">
          {title && (
            <div className="card-header border-bottom-0 px-0 mb-3">
              <div className="card-title">{title}</div>
            </div>
          )}

          {description && (
            <div className="card-body p-0 mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">{description}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementCard;
