import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { viewClientCurrentCollectionApi } from "../../../apis";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import CustomPagination from "../../../Components/CustomPagination";
import { createColumns } from "../../../helpers";
import Tabs from "../../../Components/Tabs";
import ProfileSection from "../../../Components/ProfileSection";

const DirectPvHistory = () => {
  const { id: taswiaId, partnerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(location.search).get("tab") || "pv"
  );
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(
      new URLSearchParams(location.search).get(
        new URLSearchParams(location.search).get("tab") === "pv"
          ? "pv_upliner_page"
          : "direct_pv_page"
      ),
      10
    ) || 1
  );

  const [sortKey, setSortKey] = useState(
    new URLSearchParams(location.search).get("sortKey") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    new URLSearchParams(location.search).get("sortDirection") || ""
  );
  const [pvTransactions, setPvTransactions] = useState([]);
  const [directTransactions, setDirectTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [pagination, setPagination] = useState({});
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const fetchClientsData = async () => {
      setIsLoading(true);
      try {
        const pageParam =
          currentTab === "pv" ? "pv_upliner_page" : "direct_pv_page";
        const response = await viewClientCurrentCollectionApi(
          taswiaId,
          partnerId,
          currentPage,
          pageParam
        );

        const { header, table } = response.data.data;
        setProfileData(header);
        setPvTransactions(table.pv_transactions || []);
        setDirectTransactions(table.direct_transactions ?? []);
        // setPagination(unsettledResponse.data.paginator);
      } catch (error) {
        console.error("Failed to fetch clients data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientsData();
  }, [taswiaId, currentPage]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tab", currentTab);
    if (searchTerm) params.set("search", searchTerm);
    params.set(
      currentTab === "pv" ? "pv_upliner_page" : "direct_pv_page",
      currentPage
    );
    if (sortKey) params.set("sortKey", sortKey);
    if (sortDirection) params.set("sortDirection", sortDirection);
    navigate({ search: params.toString() });
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, navigate]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    setCurrentPage(1);
  };

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column, direction) => {
    setSortKey(column.sortKey);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const hiddenColumns = ["id", "client_id", "action"]; // Adjust column keys as needed
  const customColumns = () => {
    const columns = [];

    if (currentTab === "pv") {
      return [];
    } else if (currentTab === "direct") {
      columns.push({
        name: "Action",
        selector: (row) => `Settlement - ${taswiaId} - ${row.client_id}`,
        ui: (row) => (
          <Link
            to={`/settlement/direct-pv-history/${taswiaId}/${row.client_id}`}
          >
            Details
          </Link>
        ),
      });
    }

    return columns;
  };

  const columns = useMemo(() => {
    return createColumns(
      currentTab === "pv" ? pvTransactions.data : directTransactions.data,
      customColumns(),
      hiddenColumns,
      true
    );
  }, [currentTab, pvTransactions, directTransactions]);

  const currentData =
    currentTab === "pv" ? pvTransactions.data : directTransactions.data;
  const currentPagination =
    currentTab === "pv"
      ? pvTransactions.paginator
      : directTransactions.paginator;

  const SETTLEMENT_PARTNERS_DATA_TABS = [
    {
      key: "pv",
      // label: `PV Transactions (${pvTransactions.total || 0})`,
      label: `PV Transactions`,
    },
    {
      key: "direct",
      // label: `Direct Transactions (${directTransactions.total || 0})`,
      label: `Direct Transactions `,
    },
  ];

  const { profile_pic, ...filteredProfileData } = profileData;

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Direct / PV History </h4>
      <ProfileSection
        title="Info"
        data={filteredProfileData}
        img={profile_pic}
        className="tw:w-full tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md tw-relative"
      />
      <div className="bg-white flex flex-col mt-4">
        {/* Tabs Component for Navigation */}
        <Tabs
          options={SETTLEMENT_PARTNERS_DATA_TABS}
          onTabClick={handleTabClick}
          currentTab={currentTab}
        />

        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <DataTable
            columns={columns}
            data={currentData}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
            onSort={handleSortChange}
            sortServer
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={currentPagination?.last_page}
              currentPage={currentPagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectPvHistory;
