import * as requestFromServer from "./AddOrEditPartnerCrud";
import { UISlice } from "../UISlice";
import {
  AddOrEditPartnerSlice,
  clearStepErrors,
  genericStepDataByKey,
  setCurrnetStep,
  setCurrnetStepInfo,
  setMyWalletBalance,
  setPackages,
  setStep3Message,
  setStep3paymentMethods,
} from "./AddOrEditPartnerSlice";

const { actions: AddOrEditPartnerActions } = AddOrEditPartnerSlice;
const { actions: UIActions } = UISlice;

//AddOrEditPartnerAction
export const saveStepData =
  (userInfo, stepNumber, isMultipart = false, onSuccess) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());
    return requestFromServer
      .postStepData(userInfo, stepNumber, isMultipart)
      .then((response) => {
        dispatch(
          setCurrnetStepInfo({
            stepInfo: response.data?.data?.model,
            stepNumber: stepNumber,
          })
        );
        dispatch(clearStepErrors(stepNumber));
        if (Number(response.data?.data?.next_step)) {
          if (response.data?.data?.next_step !== 7) {
            dispatch(setCurrnetStep(response.data?.data?.next_step));
          }
        } else if (
          response.data?.data?.next_step == "Waiting For Admin Verification"
        ) {
          dispatch(setStep3Message(response.data?.data?.next_step));
        }
        if (onSuccess) onSuccess(response.data?.data?.model?.id);
      })
      .catch((error) => {
        dispatch(
          AddOrEditPartnerActions.setStepErrors({
            errorsList: error?.response?.data?.message || error?.response.data,
            stepNumber: stepNumber,
          })
        );
        dispatch(AddOrEditPartnerActions?.catchError(error));
        throw error;
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

export const updateStepData =
  (userInfo, stepNumber, isMultipart = false, onSuccess, onError) =>
  (dispatch) => {
    dispatch(UIActions.openLoader());
    return requestFromServer
      .postUpdateStepData(userInfo, stepNumber, isMultipart)
      .then((response) => {
        if (Number(response.data?.data?.next_step)) {
          if (response.data?.data?.next_step !== 7) {
            dispatch(setCurrnetStep(response.data?.data?.next_step));
          }
        } else if (
          response.data?.data?.next_step == "Waiting For Admin Verification"
        ) {
          dispatch(setStep3Message(response.data?.data?.next_step));
        }
        if (onSuccess) onSuccess(response.data?.data);
      })
      .catch((error) => {
        const errorMessageArray = error?.response?.data?.message;
        let isRateLimitExceeded = false;

        if (Array.isArray(errorMessageArray)) {
          isRateLimitExceeded = errorMessageArray.some((msg) =>
            msg.includes("hostinger_out_ratelimit")
          );
        }

        if (isRateLimitExceeded) {
          if (onError) onError(["Please try later"]);
        } else {
          if (onError) onError(errorMessageArray);
        }
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

//AddOrEditPartnerAction
export const justValidateInput = (userInfo, stepNumber) => (dispatch) => {
  // dispatch(UIActions.openLoader());
  // {[fieldName]:'value'}
  return requestFromServer
    .postStepData(userInfo, stepNumber)
    .then((response) => {
      if (response?.data?.message === "success") {
        dispatch(clearStepErrors(stepNumber));
      }
    })
    .catch((error) => {
      dispatch(
        AddOrEditPartnerActions.setStepErrors({
          errorsList: error?.response?.data?.message,
          stepNumber: stepNumber,
        })
      );

      dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

// get Current PartnerStepInfo
export const getCurrentPartnerStepInfoAction =
  (clientId, onSuccess, onFail) => (dispatch) => {
    dispatch(UIActions.openLoader());
    // {[fieldName]:'value'}
    return requestFromServer
      .getCurrentPartnerStepInfo(clientId)
      .then((response) => {
        dispatch(
          setCurrnetStepInfo({ stepInfo: response.data?.data, stepNumber: 1 })
        );
        dispatch(
          setCurrnetStep(response.data?.data?.account_creation_status_id)
        );
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        if (onFail) onFail();

        // dispatch(
        //   AddOrEditPartnerActions.setStepErrors({
        //     errorsList: error?.response?.data?.message,
        //     stepNumber: stepNumber
        //   }));

        // dispatch(AddOrEditPartnerActions?.catchError(error));
      })
      .finally(() => {
        dispatch(UIActions.closeLoader());
      });
  };

//AddOrEditPartnerAction
export const resendOtpAction = (clientId, onSuccess) => (dispatch) => {
  // dispatch(UIActions.openLoader());
  // {[fieldName]:'value'}
  return requestFromServer
    .resendOtp(clientId)
    .then((response) => {
      if (onSuccess) onSuccess();
    })
    .catch((error) => {
      // dispatch(
      //   AddOrEditPartnerActions.setStepErrors({
      //     errorsList: error?.response?.data?.message,
      //     stepNumber: stepNumber
      //   }));
      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

export const checkWalletBalanceAction = (clientId) => (dispatch) => {
  dispatch(UIActions.openLoader());
  // {[fieldName]:'value'}
  return requestFromServer
    .checkWalletBalance(clientId)
    .then((response) => {
      // if (onSuccess) onSuccess()
    })
    .catch((error) => {
      dispatch(
        AddOrEditPartnerActions.setStep3EmoneyFromWalletErrors({
          errorsList: error?.response?.data?.message,
        })
      );

      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      dispatch(UIActions.closeLoader());
    });
};

export const getPaymentMethodsAction = () => (dispatch) => {
  // dispatch(UIActions.openLoader());
  // {[fieldName]:'value'}
  return requestFromServer
    .getPaymentMethods()
    .then((response) => {
      dispatch(
        setStep3paymentMethods({ paymentMethods: response?.data?.data })
      );

      // if (onSuccess) onSuccess()
    })
    .catch((error) => {
      dispatch(
        AddOrEditPartnerActions.setStep3EmoneyFromWalletErrors({
          errorsList: error?.response?.data?.message,
        })
      );

      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

export const getPackagesAction = () => (dispatch) => {
  return requestFromServer
    .getPackages()
    .then((response) => {
      dispatch(setPackages({ packages: response?.data?.data }));

      // if (onSuccess) onSuccess()
    })
    .catch((error) => {
      dispatch(
        AddOrEditPartnerActions.setStep4ValidationError({
          errorsList: error?.response?.data?.message,
        })
      );

      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

export const getMyWalletBalanceAction = () => (dispatch) => {
  return requestFromServer
    .getMyWalletBalance()
    .then((response) => {
      dispatch(setMyWalletBalance({ myWalletBalance: response?.data?.data }));

      // if (onSuccess) onSuccess()
    })
    .catch((error) => {
      dispatch(
        AddOrEditPartnerActions.setStep4ValidationError({
          errorsList: error?.response?.data?.message,
        })
      );

      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

// step5
export const getEducationalLevelsAction = () => (dispatch) => {
  return requestFromServer
    .getEducationalLevel()
    .then((response) => {
      dispatch(
        genericStepDataByKey({
          keyName: "educationalLevels",
          keyValue: response?.data?.data?.filter((el) => el.is_active == 1),
          stepNumber: 5,
        })
      );
    })
    .catch((error) => {
      // dispatch(AddOrEditPartnerActions?.catchError(error));
    })
    .finally(() => {
      // dispatch(UIActions.closeLoader())
    });
};

export const getJobsAction = () => (dispatch) => {
  return requestFromServer
    .getJobCategories()
    .then((response) => {
      dispatch(
        genericStepDataByKey({
          keyName: "jobs",
          keyValue: response?.data?.data?.filter((el) => el.is_active == 1),
          stepNumber: 5,
        })
      );
    })
    .catch((error) => {})
    .finally(() => {});
};

export const getCountriesAction = () => (dispatch) => {
  return requestFromServer
    .getCountries()
    .then((response) => {
      dispatch(
        genericStepDataByKey({
          keyName: "countries",
          keyValue: response?.data?.data?.filter((el) => el.is_active == 1),
          stepNumber: 5,
        })
      );
    })
    .catch((error) => {})
    .finally(() => {});
};

// step6
export const getPartnersBySearchAction = (keyName, searchKey) => (dispatch) => {
  return requestFromServer
    .getPartnersBySearch(searchKey)
    .then((response) => {
      dispatch(
        genericStepDataByKey({
          keyName: keyName,

          // 'allDirectPartnersMatchsSearchKey',
          keyValue: response?.data?.data,
          stepNumber: 6,
        })
      );
    })
    .catch((error) => {})
    .finally(() => {});
};
export const getDirectPartnersBySearchAction =
  (keyName, searchKey, uplinerId) => (dispatch) => {
    return requestFromServer
      .getDirectPartnersBySearch(uplinerId, searchKey)
      .then((response) => {
        dispatch(
          genericStepDataByKey({
            keyName: keyName,
            keyValue: response?.data?.data,
            stepNumber: 6,
          })
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };

export const checkUplinerPartnerHasLeftOrRightAction =
  (keyName, uplinerPartnerID) => (dispatch) => {
    return requestFromServer
      .checkUplinerPartnerHasLeftOrRight(uplinerPartnerID)
      .then((response) => {
        dispatch(
          genericStepDataByKey({
            // leftOrRightUplinerPartner
            keyName: keyName,
            keyValue: response?.data?.data,
            stepNumber: 6,
          })
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };
