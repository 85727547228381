import axios from "axios";

export function fetchMyCourses() {
  return axios.get(`/client/course/my`);
}


export function fetchSingleCourse(course_id) {
  return axios.get(`/client/course/details/${course_id}`);
}

export function rateSingleCourse(course_id, rateValue, reviewValue) {
  return axios.post(`/client/course/${course_id}`, {
    rateValue: rateValue,
    reviewValue: reviewValue
  });
}

export function fetchSingleCourseLesson(course_id, lessonId) {
  return axios.get(`/client/course/lesson/${course_id}/${lessonId}`);
}
