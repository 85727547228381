import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  getDirectPartnersBySearchAction,
  getPartnersBySearchAction,
  saveStepData,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import UplinerPartnerField from "./step6/UplinerPartnerField";
import DirectPartnerField from "./step6/DirectPartnerField";
import PasswordFields from "./step6/PasswordFields";
import {
  STEP_SIX_INITIAL_VALUES,
  STEP_SIX_SCHEMA,
  debouncedSearchHandler,
} from "./step6/utils";
import TermsField from "./step6/TermsField";
import { absoluteUrl } from "../../../_helper";
import { genericResetStep } from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";

const PartnerStepSix = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedInUser } = useSelector((state) => state?.Auth); //ar or en
  const { step1 } = useSelector((state) => state?.AddOrEditPartner);
  const { stepInfo: Step1Info } = step1;
  const [errorList, setErrorList] = useState({});
  const onSubmitSuccessFuly = () => {
    Swal.fire({
      title: "Good!",
      text: "Partner was added successfully",
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/partners");
      }
    });
  };

  const handleSubmit = async (values, { setErrors }) => {
    const ObjectToSubmit = {
      client_id: Step1Info?.id,
      direct_partner_id: +values?.direct_partner_id,
      upliner_partner_id: +values?.upliner_partner_id,
      direction: values?.direction,
      password: values?.password,
      password_confirmation: values?.password_confirmation,
    };

    try {
      await dispatch(
        saveStepData({ ...ObjectToSubmit }, 6, false, onSubmitSuccessFuly)
      );
    } catch (error) {
      const errorData = error?.response?.data?.message || error?.response?.data;
      const formErrors = {};

      if (typeof errorData === "string") {
        formErrors.general = errorData; // Assign the string error message to a general key
      } else if (Array.isArray(errorData)) {
        formErrors.general = errorData.join(", "); // Join array elements into a single string
      } else {
        for (const key in errorData) {
          if (errorData.hasOwnProperty(key)) {
            formErrors[key] = errorData[key][0]; // assuming the error messages are arrays
          }
        }
      }

      setErrorList(formErrors);
    }
  };

  useEffect(() => {
    dispatch(genericResetStep(6));
  }, [dispatch]);

  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h3 className="card-title mb-0">Step (6): Activate Partner</h3>
        <h5 className="card-title mb-0">
          Partner Name:{" "}
          <span className="text-muted">
            {Step1Info?.first_name +
              " " +
              Step1Info?.second_name +
              " " +
              Step1Info?.last_name}
          </span>
        </h5>
      </div>
      <div className="card-body tw-relative">
        <div className="tw-w-full tw-justify-end tw-flex tw-gap-8">
          {Object.keys(errorList).length > 0 && (
            <div className="alert alert-danger tw-w-full">
              {Object.values(errorList).map((item, index) => (
                <div key={index}>
                  <p className="!tw-mb-0">{item}</p>
                </div>
              ))}
            </div>
          )}
          <Link to={`/tree`} target="_blank">
            <img
              src={absoluteUrl("imgs/tree.png")}
              style={{ width: 40 }}
              alt=""
            />
          </Link>
        </div>
        <Formik
          initialValues={STEP_SIX_INITIAL_VALUES}
          validationSchema={STEP_SIX_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            touched,
            errors,
          }) => {
            return (
              <Form className="d-flex flex-column">
                {errors.submit && (
                  <div className="alert alert-danger">{errors.submit}</div>
                )}
                <UplinerPartnerField
                  values={values}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  debouncedAsyncValidation={(keyName, keyValue) =>
                    debouncedSearchHandler(
                      dispatch,
                      getPartnersBySearchAction,
                      keyName,
                      keyValue
                    )
                  }
                  touched={touched}
                  errors={errors}
                />
                <DirectPartnerField
                  values={values}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  debouncedDirectAsyncValidation={(
                    keyName,
                    keyValue,
                    uplinerId
                  ) =>
                    debouncedSearchHandler(
                      dispatch,
                      getDirectPartnersBySearchAction,
                      keyName,
                      keyValue,
                      uplinerId
                    )
                  }
                  touched={touched}
                  errors={errors}
                />
                <PasswordFields touched={touched} errors={errors} />
                <TermsField
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <button type="submit" className="btn btn-primary ml-auto">
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default PartnerStepSix;
