import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { DATATABLE_CUSTOM_STYLES } from "../../constants/datatableStyles";
import CustomPagination from "../../Components/CustomPagination";
import { partnerEMoneyRequestsApi } from "../../apis";
import { createColumns } from "../../helpers";

const EMoneyRequestsHistory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsData, setRequestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    last_page: 1,
    current_page: 1,
  });

  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true);
      try {
        const response = await partnerEMoneyRequestsApi(
          searchTerm,
          currentPage
        );
        setRequestsData(response.data.data.data);
        setPagination({
          last_page: response.data.data.last_page,
          current_page: response.data.data.current_page,
        });
      } catch (error) {
        console.error("Error fetching e-money requests:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequests();
  }, [searchTerm, currentPage]);

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterAndSortData = () => {
    return requestsData;
  };

  const hiddenColumns = ["id", "action", "must_verify_email"];

  const customColumns = [];

  const columns = useMemo(() => {
    return createColumns(requestsData, customColumns, hiddenColumns, false);
  }, [requestsData]);

  return (
    <div className="bg-white rounded-lg flex flex-col gap-2">
      <div className="relative tw-hidden">
        <input
          type="text"
          onChange={handleFilter}
          value={searchTerm}
          className="border border-blue-300 rounded-lg p-2 mb-2 col-md-12"
          placeholder="Search by method"
        />
      </div>
      <DataTable
        columns={columns}
        data={filterAndSortData()}
        customStyles={DATATABLE_CUSTOM_STYLES}
        progressPending={isLoading}
      />
      <div className="flex justify-center mt-4">
        <CustomPagination
          totalPages={pagination.last_page}
          currentPage={pagination.current_page}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default EMoneyRequestsHistory;
