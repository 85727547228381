import React, { useState, useEffect, useLayoutEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";
import {
  lastTaswiaEndDateApi,
  createTaswiaApi,
  canCreateTaswiaApi,
} from "../../../apis"; // Import APIs
import { useNavigate } from "react-router";

// Initial form values
const initialValues = {
  endDate: "",
  confirmDate: false,
};

const NewSettlement = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [lastTaswiaEndDate, setLastTaswiaEndDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Default to today's date

  const navigate = useNavigate();
  // Helper function to format the date to DD-MM-YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Fetch the last taswia end date using useEffect
  useEffect(() => {
    const fetchLastTaswiaEndDate = async () => {
      try {
        const response = await lastTaswiaEndDateApi();
        if (response.status === 200) {
          const endDate = new Date(response.data.data);
          setLastTaswiaEndDate(endDate);
        }
      } catch (error) {
        console.error("Failed to fetch last taswia end date:", error);
      }
    };

    fetchLastTaswiaEndDate();
  }, []);

  useLayoutEffect(() => {
    const checkCanCreateTaswia = async () => {
      try {
        const response = await canCreateTaswiaApi();
        if (!response?.data?.data) {
          navigate("/settlements");
        }
      } catch (error) {
        console.error("Error checking taswia creation permission:", error);
        navigate("/settlements");
      }
    };

    checkCanCreateTaswia();
  }, []);

  // Validation schema using Yup with custom validation
  const validationSchema = Yup.object().shape({
    endDate: Yup.date().required("Please select an end date."),
    confirmDate: Yup.boolean().oneOf(
      [true],
      "You must confirm the selected date is correct."
    ),
  });

  const handleSubmit = async (values) => {
    // Prepare form data
    const formData = {
      endDate: values.endDate,
      confirm: values.confirmDate ? 1 : 0, // Send 1 if confirmed
    };

    try {
      const response = await createTaswiaApi(
        formData.endDate,
        formData.confirm
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: `Settlement created successfully with end date: ${values.endDate}.`,
          icon: "success",
        });
      } else {
        // Check for error message in response and display it using Swal
        Swal.fire({
          title: "Error",
          text: response.data.message.join(", "),
          icon: "error",
        });
      }
    } catch (error) {
      // Check if the error response has a message and display it
      const errorMessage =
        error.response?.data?.message.join(", ") || error.message;
      Swal.fire({
        title: "Error",
        text: `Failed to create settlement: ${errorMessage}`,
        icon: "error",
      });
    }
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Start new settlement
        </h4>
      </div>

      <div className="tw-flex-col gap-6">
        <div className="card">
          <div className="card-body">
            <h5 className="tw-text-xl ">
              #Last settlement date is{" : "}
              <span className="tw-font-bold tw-text-primary">
                {formatDate(lastTaswiaEndDate)}
              </span>
            </h5>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="endDate">
                      Select End Date
                    </label>
                    <Flatpickr
                      id="endDate"
                      name="endDate"
                      className="form-control"
                      value={values.endDate}
                      onChange={([date]) =>
                        setFieldValue(
                          "endDate",
                          date.toISOString().split("T")[0]
                        )
                      } // Convert to YYYY-MM-DD
                      options={{
                        minDate: lastTaswiaEndDate, // Ensure the user cannot select a date before lastTaswiaEndDate
                      }}
                    />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-check mb-3">
                    <Field
                      type="checkbox"
                      id="confirmDate"
                      name="confirmDate"
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                      htmlFor="confirmDate"
                    >
                      I confirm that the selected date is correct.
                    </label>
                    <ErrorMessage
                      name="confirmDate"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="tw-flex tw-w-full tw-justify-end">
                    <button type="submit" className="primary-btn">
                      Start Settlement
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSettlement;
