import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminPartnersData: [],
  isLoading: false,
  error: "",
  pagination: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0,
  },
};

export const AdminPartnersSlice = createSlice({
  name: "AdminPartners",
  initialState,
  reducers: {
    setAdminPartnersData: (state, action) => {
      state.adminPartnersData = action.payload.data;
      state.pagination = action.payload.paginator;
    },
    clearAdminPartnersData: (state) => {
      state.adminPartnersData = [];
      state.pagination = {
        current_page: 1,
        last_page: 1,
        per_page: 15,
        total: 0,
      };
    },
    catchAdminError: (state, action) => {
      state.error = action.payload;
    },
    setAdminLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setAdminPartnersData,
  clearAdminPartnersData,
  catchAdminError,
  setAdminLoading,
} = AdminPartnersSlice.actions;
export default AdminPartnersSlice.reducer;
