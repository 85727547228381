import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom'
import Modal from "react-modal";
import {
  viewPaymentVerification,
  verifyClientPayment,
} from "../../Redux/Requests/RequestsActions";
import RequestSection from "../../Components/RequestDetails/RequestSection";
import RequestHeader from "../../Components/RequestDetails/RequestHeader";
import AcceptModal from "../../Components/RequestDetails/AcceptModal";
import RejectModal from "../../Components/RequestDetails/RejectModal";
import RequestActions from "../../Components/RequestDetails/RequestActions";
import PaymentDocument from "../../Components/RequestDetails/PaymentDocument";

Modal.setAppElement("#root");

const RequestDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { requestDetails, isLoading } = useSelector((state) => state.Requests);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [eMoney, setEMoney] = useState(0);
  const [acceptanceNote, setAcceptanceNote] = useState("");
  const [rejectionNote, setRejectionNote] = useState("");

  useEffect(() => {
    dispatch(viewPaymentVerification(id));
  }, [dispatch, id]);

  const onSuccessHandler = () => {
    navigate('/requests')
  }

  const handleVerifyPayment = (actionType) => {
    dispatch(
      verifyClientPayment(id, actionType, eMoney, acceptanceNote, rejectionNote, onSuccessHandler)
    );
    setIsAcceptModalOpen(false);
    setIsRejectModalOpen(false);
  };

  if (isLoading || !requestDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="request-details">
      <RequestHeader requestDetails={requestDetails} />
      <div className="request-body">
        <div className="row flex-grow-1">
          <div className="col-md-6">
            <RequestSection
              title="Partner Data"
              data={requestDetails.partner_data}
            />
            <RequestSection
              title="Payment Value"
              data={requestDetails.payment_value}
            />
            <RequestSection
              title="Payment Date"
              data={requestDetails.payment_date}
            />
            <RequestSection
              title="Payment Notes"
              data={requestDetails.payment_notes || "No notes available"}
            />
          </div>
          <div className="col-md-6">
            <RequestSection
              title="Requested By"
              data={requestDetails.requested_by}
            />
            <RequestSection
              title="Payment Method"
              data={requestDetails.payment_method}
            />
            <RequestSection
              title="Payment Transaction No."
              data={requestDetails.payment_transaction_number}
            />
          </div>
        </div>

        <PaymentDocument documentUrl={requestDetails.payment_document} />
      </div>
      {requestDetails.request_status.toLowerCase() === "pending" && (
        <RequestActions
          onAccept={() => setIsAcceptModalOpen(true)}
          onReject={() => setIsRejectModalOpen(true)}
        />
      )}
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        handleVerifyPayment={handleVerifyPayment}
        eMoney={eMoney}
        setEMoney={setEMoney}
        acceptanceNote={acceptanceNote}
        setAcceptanceNote={setAcceptanceNote}
        requestDetails={requestDetails}
      />
      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        handleVerifyPayment={handleVerifyPayment}
        rejectionNote={rejectionNote}
        setRejectionNote={setRejectionNote}
        requestDetails={requestDetails}
      />
    </div>
  );
};

export default RequestDetails;
