import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../Redux/Auth/AuthActions";
import { debounce } from "lodash";

const initialValues = {
  transfer_to: "",
  transfer_value: "",
  notes: "",
  confirm: false,
};

// Validation schema
const validationSchema = Yup.object().shape({
  transfer_to: Yup.string().required("Required"),
  transfer_value: Yup.number()
    .required("Required")
    .positive("Must be a positive number"),
  notes: Yup.string().notRequired(),
  confirm: Yup.boolean().oneOf([true], "Confirmation is required"),
});

// API functions
const searchTransferTo = async (userSearch) => {
  try {
    const response = await axios.get(
      `/client/emoney-transfer/search/${userSearch}`
    );
    return response.data;
  } catch (error) {
    console.error("Error searching for user:", error);
    return [];
  }
};

const checkWalletBalance = async (transferValue) => {
  try {
    const response = await axios.get(
      `/client/emoney-transfer/check-wallet/${transferValue}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error checking wallet balance:", error);
    return false;
  }
};

const submitTransfer = async (values) => {
  try {
    const response = await axios.post("/client/emoney-transfer", {
      transfer_to: values.transfer_to,
      transfer_value: values.transfer_value,
      notes: values.notes,
      confirm: values.confirm ? 1 : 0,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting transfer:", error);
    throw error;
  }
};
const TransferEMoney = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [walletCheck, setWalletCheck] = useState(null);
  const [selectedName, setSelectedName] = useState(""); // Store selected name and referral code
  const dropdownRef = useRef(null); // Ref for the dropdown
  const inputRef = useRef(null); // Ref for the input

  const dispatch = useDispatch();
  const walletBalance = useSelector((state) => state.Auth.walletBalance);

  useEffect(() => {
    dispatch(fetchWalletBalance());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setSearchResults([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTransferToChange = debounce(async (value) => {
    if (value.length > 0) {
      const results = await searchTransferTo(value);
      setSearchResults(results.data);
    } else {
      setSearchResults([]);
    }
  }, 500);

  const handleTransferValueChange = async (value, setFieldValue) => {
    if (value) {
      const isBalanceSufficient = await checkWalletBalance(value);
      setWalletCheck(isBalanceSufficient);
      setFieldValue("transfer_value", value);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await submitTransfer(values);
      Swal.fire({
        title: "Success",
        text: "E-Money Has Been Successfully Transferred",
        icon: "success",
      });
      dispatch(fetchWalletBalance());
      resetForm();
      setSelectedName("");
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "There was an error sending the request",
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="tw-flex-col gap-6">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">Transfer E-Money</h4>
          <h5 className="card-title mb-0">
            Current Wallet:{" "}
            <span className="text-muted">{walletBalance} e.m</span>
          </h5>
        </div>
        <div className="card-body">
          <Formik
            initialValues={{
              transfer_to: "",
              transfer_value: "",
              notes: "",
              confirm: false,
            }}
            validationSchema={Yup.object().shape({
              transfer_to: Yup.string().required("Required"),
              transfer_value: Yup.number()
                .required("Required")
                .positive("Must be a positive number"),
              notes: Yup.string().notRequired(),
              confirm: Yup.boolean().oneOf([true], "Confirmation is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="transfer_to"
                    >
                      Transfer To
                    </label>
                    <Field
                      type="text"
                      name="transfer_to"
                      className={`form-control ${
                        touched.transfer_to
                          ? errors.transfer_to
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                      value={selectedName}
                      innerRef={inputRef}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedName(value);
                        handleTransferToChange(value);
                      }}
                    />
                    <ErrorMessage
                      name="transfer_to"
                      component="div"
                      className="invalid-feedback"
                    />
                    {searchResults.length > 0 && (
                      <ul
                        ref={dropdownRef}
                        className="tw-absolute tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-w-full tw-mt-2 tw-max-h-48 tw-overflow-y-auto tw-z-10"
                      >
                        {searchResults.map((result) => (
                          <li
                            key={result.id}
                            className="tw-px-4 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                            onClick={() => {
                              setFieldValue("transfer_to", result.id);
                              setSelectedName(
                                `${result.name} (${result.referral_code})`
                              );
                              setSearchResults([]); // Close the dropdown
                            }}
                          >
                            {result.name} ({result.referral_code})
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="transfer_value"
                    >
                      Transfer Value (EM)
                    </label>
                    <Field
                      type="text"
                      name="transfer_value"
                      className={`form-control ${
                        touched.transfer_value
                          ? errors.transfer_value
                            ? "custom-invalid-input"
                            : ""
                          : ""
                      }`}
                      onChange={(e) => {
                        const { value } = e.target;
                        handleTransferValueChange(value, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="transfer_value"
                      component="div"
                      className="invalid-feedback"
                    />
                    {walletCheck === false && (
                      <div className="text-danger">
                        Insufficient wallet balance.
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label" htmlFor="notes">
                      Notes
                    </label>
                    <Field
                      as="textarea"
                      name="notes"
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-check mb-3 tw-flex tw-items-end tw-w-full">
                  <input
                    type="checkbox"
                    className={`form-check-input tw-h-5 tw-w-5 ${
                      touched.confirm && errors.confirm ? "is-invalid" : ""
                    }`}
                    id="confirmCheckbox"
                    name="confirm"
                    checked={values.confirm}
                    onChange={(e) => setFieldValue("confirm", e.target.checked)}
                  />
                  <label
                    className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                    htmlFor="confirmCheckbox"
                  >
                    I confirm that the transfer process is correct, and I can't
                    cancel it.
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Transfer
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TransferEMoney;
