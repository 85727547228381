import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProfileSection from "../../../Components/ProfileSection";
import { viewPartnerSettlementDetailsApi } from "../../../apis";
import SettlementTableSection from "../../../Components/SettlementTableSection";
import BackBtn from "../../../Components/BackBtn";

const SettledPartner = () => {
  const [partnerData, setPartnerData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: taswiaId, partnerId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await viewPartnerSettlementDetailsApi(
          taswiaId,
          partnerId
        );
        setPartnerData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(
          error.response.data.message || "An error occurred while fetching data"
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [taswiaId, partnerId]);

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="alert alert-danger">{error}</div>;

  if (!partnerData) return <div>No partner data available</div>;
  const { partner_profile, settlement_data, settlement_details } = partnerData;
  const { taswia_data, settlement_summary } = settlement_data;

  const { image, wallet_data, ...filteredPartnerData } = partner_profile;

  const partnerDetails = {
    ...filteredPartnerData,
    ...wallet_data,
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-justify-between">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Settlement Partner Details
        </h4>

        <BackBtn
          text={"Back To List"}
          link={`/settlement/${taswiaId}/partner-list`}
        />
      </div>
      <ProfileSection
        title="Partner Profile"
        data={partnerDetails}
        img={image}
        link={{
          url: `/profile/${partnerId}`,
          title: "Go to profile",
        }}
      />
      <ProfileSection title="Settlement Data" data={taswia_data} />
      <ProfileSection title="Settlement Summary" data={settlement_summary} />

      {/* 

      <ProfileSection
        title="Current Collection"
        data={partnerData.partner_current_collection}
        columns={3}
        link={{
          url: `/settlement/direct-pv-history/${taswiaId}/${partnerId}`,
          title: "PV/Direct History",
        }}
      />

      <SettlementTableSection data={partnerData.expected_earning_from_pv} /> */}
    </div>
  );
};

export default SettledPartner;
