import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {
  replaceEmoneyRequestApi,
  viewEarningWalletApi,
} from "../../apis";
import ReplaceEMoneyRequestsHistory from "./ReplaceEMoneyRequestsHistory";
import { useNavigate } from "react-router-dom";

const initialValues = {
  emoney: "",
  notes: "",
  confirm: false,
};

const validationSchema = Yup.object().shape({
  emoney: Yup.number().required("Required").positive("Must be greater than 0"),
  notes: Yup.string().required("Required"),
  confirm: Yup.boolean().oneOf([true], "You must confirm the replace"),
});

const ReplaceEmoney = () => {
  const [userEarningWallet, setUserEarningWallet] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = {
      emoney: values.emoney,
      notes: values.notes,
      confirm: values.confirm ? 1 : 0,
    };

    try {
      await replaceEmoneyRequestApi(formData);
      Swal.fire({
        title: "Success",
        text: "The request has been sent successfully",
        icon: "success",
      });
      resetForm();
      navigate(0)
    } catch (error) {
      const errorMessage =
        error.response?.data?.message.join(", ") || error.message;

      Swal.fire({
        title: "Error",
        text: `${errorMessage}`,
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchEarningWallet = async () => {
      try {
        const response = await viewEarningWalletApi();
        setUserEarningWallet(response.data.data);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchEarningWallet();
  }, []);

  return (
    <div className="tw-flex-col gap-6">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">Replace E-Money</h4>
          <h4 className="tw-capitalize tw-text-lg !tw-mb-0">
            current earning wallet{" "}
            <span className="tw-text-primary tw-font-medium ">
              {userEarningWallet} e.m
            </span>
          </h4>
        </div>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="emoney"
                    >
                      E-Money
                    </label>
                    <Field
                      type="number"
                      name="emoney"
                      className={`form-control ${
                        touched.emoney && errors.emoney
                          ? "custom-invalid-input"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="emoney"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label
                      className="form-label required-label"
                      htmlFor="notes"
                    >
                      Notes
                    </label>
                    <Field
                      as="textarea"
                      placeholder="Kindly provide details on how you'd like to receive the money"
                      name="notes"
                      className={`form-control ${
                        touched.notes && errors.notes
                          ? "custom-invalid-input"
                          : ""
                      }`}
                      rows="4"
                    />
                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-check mb-3 tw-flex tw-items-end tw-w-full">
                    <input
                      type="checkbox"
                      className={`form-check-input tw-h-5 tw-w-5 ${
                        touched.confirm && errors.confirm ? "is-invalid" : ""
                      }`}
                      id="confirmCheckbox"
                      name="confirm"
                      checked={values.confirm}
                      onChange={(e) =>
                        setFieldValue("confirm", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                      htmlFor="confirmCheckbox"
                    >
                      I confirm that the replace process is correct, and I can't
                      cancel it.
                    </label>
                    <ErrorMessage
                      name="confirm"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">Replace E-Money Requests History</h4>
        </div>
        <div className="card-body">
          <ReplaceEMoneyRequestsHistory />
        </div>
      </div>
    </div>
  );
};

export default ReplaceEmoney;
