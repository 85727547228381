import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  treeData: null,
  treeMaxLevel: null,
  isLoading: false,
  error: "",
};

export const TreeSlice = createSlice({
  name: "Tree",
  initialState,
  reducers: {
    setTreeData: (state, action) => {
      state.treeData = action.payload.treeData;
      state.treeMaxLevel = action.payload.treeMaxLevel;
    },
    clearTreeData: (state) => {
      state.treeData = {};
      state.treeMaxLevel = null; // Reset to default
    },
    catchError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setTreeData, clearTreeData, catchError, setLoading } =
  TreeSlice.actions;
export default TreeSlice.reducer;
