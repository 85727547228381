import React from "react";
import { Field, ErrorMessage } from "formik";

const PasswordFields = ({ touched, errors }) => (
  <div className="row">
    <div className="col-md-5">
      <label className="form-label required-label" htmlFor="password">
        Password
      </label>
      <Field
        name="password"
        type="password"
        placeholder="Password"
        className={`form-control ${
          errors.password && touched.password ? "is-invalid" : ""
        }`}
      />
      <ErrorMessage
        name="password"
        component="div"
        className="invalid-feedback"
      />
    </div>
    <div className="col-md-5">
      <label
        className="form-label required-label"
        htmlFor="password_confirmation"
      >
        Password Confirmation
      </label>
      <Field
        name="password_confirmation"
        type="password"
        placeholder="Password Confirmation"
        className={`form-control ${
          errors.password_confirmation && touched.password_confirmation
            ? "is-invalid"
            : ""
        }`}
      />
      <ErrorMessage
        name="password_confirmation"
        component="div"
        className="invalid-feedback"
      />
    </div>
  </div>
);

export default PasswordFields;
