import React from "react";
import SelectedPackageCourseRow from "./SelectedPackageCourseRow";
import { useDispatch, useSelector } from "react-redux";

const SelectedPackageCourse = () => {
  const { step4 } = useSelector((state) => state?.AddOrEditPartner);
  const { lang } = useSelector((state) => state?.UI); //ar or en
  const { AllPackages, myWalletBalance, selectedPackageFullObject } = step4;
  const dispatch = useDispatch();
  const registerationFees = {
    id: 0,
    course_name_ar: "مصاريف التسجيل",
    course_name_en: "Registeration Fees",
    course_description_ar: "مصاريف التسجيل",
    course_description_en: "Registeration Fees",
    image: null,
    e_money: `${selectedPackageFullObject.registration_price} E.M`,
    course_price: null,
    weight: null,
    is_active: 1,
    created_by: null,
    created_at: "2024-07-01T18:44:03.000000Z",
    updated_at: "2024-07-01T18:44:03.000000Z",
    pivot: {
      package_id: 1,
      course_id: 1,
      e_money: 0,
      weight: 0,
    },
  };
  const coursesWithRegisterationFees = [
    registerationFees,
    ...(selectedPackageFullObject?.courses?.filter(
      (course) => course?.e_money
    ) || []),
  ];

  return (
    <>
      <div
        className="table-responsive border-bottom table-courses"
        data-toggle="lists"
        data-lists-values='["js-lists-values-employee-name"]'
      >
        <table className="table mb-0">
          <thead>
            <tr>
              <th>No</th>
              <th>Course</th>
              {/* <th>Type</th> */}
              <th>E-Money</th>
            </tr>
          </thead>
          <tbody className="list" id="staff">
            {selectedPackageFullObject?.courses?.length > 0 &&
              coursesWithRegisterationFees.map((course, courseIndex) => (
                <React.Fragment key={courseIndex}>
                  <SelectedPackageCourseRow
                    course={course}
                    courseIndex={courseIndex}
                  />
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SelectedPackageCourse;
