import React from "react";
import { absoluteUrl } from "../../_helper";

const AuthPagesTemplate = ({ children }) => {
  const { hostname, port } = window.location;
  return (
    <div className="authopages-template-wrapper d-flex">
      <div className="img-side h-100">
        {port === "3001" ||
        port === "3002" ||
        hostname === "admin.winlifenet.com" ||
        hostname === "admin-devenv.winlifenet.com" ? (
          <img
            src={absoluteUrl("imgs/admin-image.jpg")}
            className="w-100 h-100"
            alt=""
          />
        ) : (
          <img
            src={absoluteUrl("imgs/in-signin-image.jpg")}
            className="w-100 h-100"
            alt=""
          />
        )}
      </div>
      <div className="form-side h-100 d-flex align-items-center">
        <div className="auth-form-container m-auto d-flex flex-column">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthPagesTemplate;
