import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  viewReplaceEmoneyDetails,
  acceptReplaceEmoneyRequest,
  rejectReplaceEmoneyRequest,
} from "../../../Redux/ReplaceEmoney/ReplaceEmoneyActions";
import RequestSection from "../../../Components/RequestDetails/RequestSection";
import RequestHeader from "../../../Components/RequestDetails/RequestHeader";
import RequestActions from "../../../Components/RequestDetails/RequestActions";
import ReplaceAcceptModal from "../../../Components/ReplaceDetails/ReplaceAcceptModal";
import ReplaceRejectModal from "../../../Components/ReplaceDetails/ReplaceRejectModal";
import { earningWalletApi } from "../../../apis";

Modal.setAppElement("#root");

const ReplaceEmoneyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { replaceEmoneyDetails, isLoading } = useSelector(
    (state) => state.ReplaceEmoney
  );
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [eMoney, setEMoney] = useState(0);
  const [acceptanceNote, setAcceptanceNote] = useState("");
  const [rejectionNote, setRejectionNote] = useState("");
  const [userWallet, setUserWallet] = useState();

  const fetchUserWallet = async () => {
    try {
      const response = await earningWalletApi(id);
      setUserWallet(response.data.data);
    } catch (error) {
      console.error("Error fetching user wallet:", error);
    }
  };
  useEffect(() => {
    dispatch(viewReplaceEmoneyDetails(id));
    fetchUserWallet();
  }, [dispatch, id]);
  console.log("userWallet ====>", userWallet);
  const onSuccessHandler = () => {
    navigate("/replace-emoney");
  };

  const handleVerifyReplaceEmoneyRequest = (actionType) => {
    if (actionType === "accept") {
      dispatch(
        acceptReplaceEmoneyRequest(id, acceptanceNote, onSuccessHandler)
      );
    } else {
      dispatch(rejectReplaceEmoneyRequest(id, rejectionNote, onSuccessHandler));
    }
    setIsAcceptModalOpen(false);
    setIsRejectModalOpen(false);
  };

  if (isLoading || !replaceEmoneyDetails) {
    return <div>Loading...</div>;
  }

  const userDetails = {
    ...replaceEmoneyDetails.partner_data,
    wallet: userWallet,
  };
  return (
    <div className="request-details">
      <RequestHeader
        requestDetails={replaceEmoneyDetails}
        title="Replace E-Money Request Details"
      />
      <div className="request-body">
        <div className="row flex-grow-1">
          <div className="col-md-6">
            <RequestSection
              title="Partner Data"
              data={replaceEmoneyDetails.partner_data}
            />

            <RequestSection
              title="Partner Notes"
              data={replaceEmoneyDetails.partner_notes || "No notes available"}
            />
          </div>
        </div>
      </div>
      {replaceEmoneyDetails.request_status.toLowerCase() === "pending" && (
        <RequestActions
          onAccept={() => setIsAcceptModalOpen(true)}
          onReject={() => setIsRejectModalOpen(true)}
        />
      )}
      <ReplaceAcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        handleVerifyPayment={() => handleVerifyReplaceEmoneyRequest("accept")}
        eMoney={eMoney}
        setEMoney={setEMoney}
        acceptanceNote={acceptanceNote}
        setAcceptanceNote={setAcceptanceNote}
        requestDetails={replaceEmoneyDetails}
        userDetails={userDetails}
      />
      <ReplaceRejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        handleVerifyPayment={() => handleVerifyReplaceEmoneyRequest("reject")}
        rejectionNote={rejectionNote}
        setRejectionNote={setRejectionNote}
        requestDetails={replaceEmoneyDetails}
        userDetails={userDetails}
      />
    </div>
  );
};

export default ReplaceEmoneyDetails;
