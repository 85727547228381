import React from "react";
import { Field, ErrorMessage } from "formik";

const TermsField = ({ handleBlur, setFieldValue, values }) => (
  <div className="row mt-3">
    <div className="col-12">
      <div className="custom-control custom-checkbox">
        <Field
          type="checkbox"
          className="custom-control-input"
          name="shortTerms"
          id="agree"
          checked={values.shortTerms}
          onChange={(e) => setFieldValue("shortTerms", e.target.checked)}
          onBlur={handleBlur}
        />
        <label htmlFor="agree" className="custom-control-label">
          I read and agree on{" "}
          <span className="text-danger"> Terms of Use </span> and all of data is
          correct.
        </label>
      </div>
      <ErrorMessage
        name="shortTerms"
        component="div"
        className="invalid-feedback"
      />
    </div>
  </div>
);

export default TermsField;
