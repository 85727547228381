import React, { useEffect, useState } from 'react'
import { json, Link, useParams } from 'react-router-dom'
import { splitTime } from '../../../_helper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCourse, rateSingleCourse } from '../../../Redux/PartnersCourses/PartnersCoursesActions';
import RatingReview from '../../../Components/RatingReview';

const SingleCourse = () => {
    const { singleCourseInfo } = useSelector((state) => state?.partnersCourses);
    const { lang } = useSelector((state) => state?.UI);

    const [rating, setRating] = useState(0);
    const [reviewValue, setReviewValue] = useState('');
    const [madeReviewBefore, setMadeReviewBefore] = useState(false);


    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const { id } = useParams()
    const dispatch = useDispatch()
    // const videoJsOptions = {
    //     autoplay: false,
    //     controls: true,
    //     responsive: true,
    //     fluid: true,
    //     playbackRates: [0.5, 1, 1.5, 2],
    //     sources: [
    //         {
    //             src: singleCourseInfo?.videoUrl,
    //             type: 'video/mp4',
    //         },
    //     ],
    // };

// wow2222ضثصضثضصثض
    const handleSubmitReview = () => {
        dispatch(rateSingleCourse(id, rating, reviewValue, () => setMadeReviewBefore(true)))
    }

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleCourse(id))
        }
    }, [])



    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <h1 className="h2 text-capitalize">
                        {singleCourseInfo[`course_name_${lang}`]}
                    </h1>
                </div>
                <div className="col-md-8">
                    <div className="card">
                        {/* "https://drive.google.com/file/d/1GvjCW7iI5K63x-xecWwmUgWsJU-koJ4v/preview"  */}
                        {singleCourseInfo?.course_promo_video &&
                            <iframe title={singleCourseInfo?.courseName} src={`${singleCourseInfo?.course_promo_video}`} width="100%" height="480"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        }
                        {/* <GenericVideoPlayer options={videoJsOptions} /> */}
                        <div className="card-body">
                            {singleCourseInfo[`course_description_${lang}`]}
                        </div>
                    </div>

                    <div className="row">
                        <div className={`${singleCourseInfo?.lessons?.lesson && singleCourseInfo?.lessons?.live ? 'col-xl-6' : 'col-12'}`}>
                            {singleCourseInfo?.lessons?.lesson?.length > 0 && (
                                <>
                                    <div className="card">


                                        <h3 className="h5 tw-text-white tw-bg-primary p-2 mb-0">
                                            Course Lessons
                                        </h3>
                                        <ul className="card list-group list-group-fit">
                                            {
                                                singleCourseInfo?.lessons?.lesson.map((v, vi) =>
                                                (<li className="list-group-item" key={vi}>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <div className="text-muted">{vi + 1}.</div>
                                                        </div>
                                                        <div className="media-body">
                                                            <Link to={`/partners/courses/${singleCourseInfo?.course_id}/${v?.lesson_id}`}>{v?.[`lesson_name_${lang}`]}</Link>
                                                        </div>
                                                        <div className="media-right">
                                                            <small className="text-muted-light">{v?.lesson_duration}</small>
                                                        </div>
                                                    </div>
                                                </li>))
                                            }

                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={`${singleCourseInfo?.lessons?.lesson && singleCourseInfo?.lessons?.live ? 'col-xl-6' : 'col-12'}`}>
                            {singleCourseInfo?.lessons?.live?.length > 0 && (
                                <>
                                    <div className="card">
                                        <h3 className="h5 tw-text-white tw-bg-primary p-2 mb-0">
                                            Recorded Sessions
                                        </h3>
                                        <ul className="card list-group list-group-fit">
                                            {
                                                singleCourseInfo?.lessons?.live.map((v, vi) =>
                                                (<li className="list-group-item" key={vi}>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <div className="text-muted">{vi + 1}.</div>
                                                        </div>
                                                        <div className="media-body">
                                                            <Link to={`/partners/courses/${singleCourseInfo?.course_id}/${v?.lesson_id}`}>{v?.[`lesson_name_${lang}`]}</Link>
                                                        </div>
                                                        <div className="media-right">
                                                            <small className="text-muted-light">{v?.lesson_duration}</small>
                                                        </div>
                                                    </div>
                                                </li>))
                                            }

                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </div>
                <div className="col-md-4">

                    {singleCourseInfo?.instructor?.length > 0 && singleCourseInfo?.instructor?.map(instructor => (
                        <div className="card" key={instructor?.instructor_id}>
                            <div className="card-header">
                                <div className="media align-items-center">

                                    <div className="media-left">
                                        <div className=" rounded-circle tw-overflow-hidden" style={{ width: 75, height: 75 }}>
                                            <img src={instructor[`instructor_image`] || "/imgs/partner.png"} alt={instructor[`instructor_name_${lang}`]} className="w-100 h-100 object-fit-cover" />
                                        </div>
                                    </div>


                                    <div className="media-body d-flex flex-column">
                                        <h4 className="card-title"><a href="fixed-instructor-profile.html">{instructor[`instructor_name_${lang}`]}</a></h4>
                                        <p className="card-subtitle">{instructor[`instructor_title_${lang}`]}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <p>{instructor[`instructor_bio_${lang}`]}</p>
                            </div>
                        </div>
                    ))}

                    {singleCourseInfo?.socials?.filter(s => s.is_active).length > 0 &&
                        (
                            <div className="card">
                                <div className="card-header">
                                    <div className="media">
                                        Social Media Channels
                                    </div>
                                </div>
                                <div className="card-body d-flex" style={{ gap: 10 }}>
                                    {singleCourseInfo?.socials?.map((sml) =>
                                    (<a href={sml?.pivot?.link} target='_blank' rel="noreferrer" key={sml?.id} className='fs-3 text-primary'>
                                        <i className={`fa fa-${sml?.platform_name?.toLowerCase()}`}></i>
                                    </a>)
                                    )}
                                </div>
                            </div>
                        )
                    }


                    <div className="card">
                        <ul className="list-group list-group-fit">
                            <li className="list-group-item">
                                <div className="media align-items-center">
                                    <div className="media-left">
                                        <i className="material-icons text-muted-light">schedule</i>
                                    </div>
                                    <div className="media-body">
                                        {splitTime(singleCourseInfo?.course_duration)?.hours} <small className="text-muted">hrs</small> &nbsp; {splitTime(singleCourseInfo?.course_duration)?.mins} <small className="text-muted">min</small>


                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="media align-items-center">
                                    <div className="media-left">
                                        <i className="material-icons text-muted-light">assessment</i>
                                    </div>
                                    <div className="media-body flex flex-column">
                                        {singleCourseInfo?.course_level}
                                        <div className="">

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {!madeReviewBefore &&
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Ratings</h4>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <RatingReview
                                    totalStars={5}
                                    currentRating={rating}
                                    onRatingChange={handleRatingChange}
                                    editable={true}  // Allows editing
                                />
                                <textarea
                                    value={reviewValue}
                                    onChange={(e) => setReviewValue(e.target.value)}
                                    className="form-control mt-2"
                                    placeholder='please add you review here'
                                ></textarea>

                                {rating > 0 && reviewValue.trim('').length > 0 &&
                                    <div className="tw-mt-4 tw-mr-auto">
                                        <button
                                            onClick={handleSubmitReview}
                                            className="btn btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                }

                                {/* <div className="rating">
                                {[...Array(5)].map((_, index) => (
                                    <i key={index} className="material-icons">
                                        {renderStarIcon(index, singleCourseInfo?.courseRating)}
                                    </i>
                                ))}
                            </div>
                            <small className="text-muted">{singleCourseInfo?.numberOfRaters} ratings</small> */}
                            </div>
                        </div>
                    }
                </div>
            </div >
        </div >
    )
}

export default SingleCourse
