import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomPagination from "../../../Components/CustomPagination";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import { createColumns } from "../../../helpers";
import { viewSettlementHistoryApi } from "../../../apis";
import ProfileSection from "../../../Components/ProfileSection";
import BackBtn from "../../../Components/BackBtn";

const SettlementHistory = () => {
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resData, setResData] = useState({});
  const [settledPartners, setSettledPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const { id: taswiaId } = useParams();

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    setError(null); // Reset error state before fetching
    try {
      const response = await viewSettlementHistoryApi(taswiaId, page);
      setResData(response.data.data || []);
      const partnersData = response.data.data.settled_partners || [];
      setSettledPartners(
        Array.isArray(partnersData) ? partnersData : Object.values(partnersData)
      );
      setPagination(response.data.paginator || {});
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("Failed to fetch data. Please try again later."); // Set error message
      setResData([]);
      setSettledPartners([]);
      setPagination({});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const hiddenColumns = ["id", "client_id", "created_by"];
  const customColumns = [];
  const { settlement_data, total_partners } = resData || {};

  const columns = useMemo(() => {
    return createColumns(settledPartners, customColumns, hiddenColumns);
  }, [settledPartners]);

  if (error) return <div className="alert alert-danger">{error}</div>;

  if (!settledPartners.length)
    return <div className="text-right">No partner data available</div>;

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      <div className="tw-flex tw-justify-between">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Settlement History</h4>

        <BackBtn text={"Back To List"} link={`/settlements/`} />
      </div>
      <ProfileSection
        title="Settlement Data"
        data={settlement_data || {}}
        className="tw:w-full tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md tw-relative"
      />

      <div className="bg-white flex flex-col mt-4">
        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <h3 className="tw-text-xl tw-font-semibold tw-text-red-600 tw-mb-4 tw-border-b tw-pb-2">
            No of Settled Partners #{total_partners}
          </h3>
          <DataTable
            columns={columns}
            data={settledPartners}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlementHistory;
