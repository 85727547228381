import React from "react";

const RequestSection = ({ title, data, unit }) => (
  <div className="request-section">
    <h3>{title}</h3>
    {typeof data === "object" ? (
      <>
        <p>{data?.name || data?.partner_name}</p>
        <p>{data?.phone || data?.partner_phone}</p>
      </>
    ) : (
      <p>
        {data} {unit}
      </p>
    )}
  </div>
);

export default RequestSection;
