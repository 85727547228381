import React from "react";

const RequestActions = ({ onAccept, onReject }) => (
  <div className="request-actions">
    <button className="accept-btn" onClick={onAccept}>
      Accept
    </button>
    <button className="reject-btn" onClick={onReject}>
      Reject
    </button>
  </div>
);

export default RequestActions;
