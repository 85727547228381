import React, { useState, useEffect } from "react";

const Tabs = ({ options, onTabClick, currentTab }) => {
  const [activeTab, setActiveTab] = useState(currentTab || options[0].key);
  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const handleTabClick = (key) => {
    setActiveTab(key);
    onTabClick(key);
  };

  return (
    <div>
      {options.map((option) => (
        <button
          key={option.key}
          className={`btn px-4 py-2 ${
            activeTab === option.key
              ? "bg-white shadow-lg tw-text-primary tw-font-bold"
              : "text-gray-300"
          }`}
          onClick={() => handleTabClick(option.key)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
