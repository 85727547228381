import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ BreadcrumbItems, ...props }) => {
    // 
    return (
        <ol className="breadcrumb">
            {BreadcrumbItems?.length > 0 && BreadcrumbItems?.map(
                (item, itemIndex) =>
                (<li className={`breadcrumb-item ${itemIndex + 1 === BreadcrumbItems?.length ? 'active' : ''}`} key={itemIndex}>
                    {itemIndex + 1 !== BreadcrumbItems?.length ?
                        <Link to={item?.link}>{item?.title}</Link> :
                        item?.title
                    }
                </li>)
            )}


        </ol>
    )
}

export default Breadcrumbs
