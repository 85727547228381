import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import GenericVideoPlayer from '../../../Components/GenericVideoPlayer';
import { renderStarIcon, splitTime } from '../../../_helper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCourseLesson } from '../../../Redux/PartnersCourses/PartnersCoursesActions';

const CourseSingleLesson = () => {

    const dispatch = useDispatch()
    const { singleLessonInfo } = useSelector((state) => state?.partnersCourses);
    const { lang } = useSelector((state) => state?.UI);

    const { id, lessonId } = useParams()
    useEffect(() => {
        if (id && lessonId) {
            dispatch(fetchSingleCourseLesson(id, lessonId))
        }
    }, [])


    // const videoJsOptions = {
    //     autoplay: false,
    //     controls: true,
    //     responsive: true,
    //     fluid: true,
    //     playbackRates: [0.5, 1, 1.5, 2],
    //     sources: [
    //         {
    //             src: singleLessonInfo?.videoUrl,
    //             type: 'video/mp4',
    //         },
    //     ],
    // };

    return (
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <h1 className="h2 text-capitalize">
                        {singleLessonInfo[`lesson_name_${lang}`]}
                    </h1>
                </div>
                <div className="col-md-8">
                    <div className="card">
                        <iframe title={singleLessonInfo[`lesson_name_${lang}`]} src={singleLessonInfo?.link} width="100%" height="480"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>

                        {/* <GenericVideoPlayer options={videoJsOptions} /> */}
                        <div className="card-body">
                            {singleLessonInfo[`lesson_description_${lang}`]}
                        </div>
                    </div>

                </div>
                <div className="col-md-4">
                    {singleLessonInfo?.lesson_type &&
                        <div className="card">
                            <div className="card-header">
                                Video Category
                            </div>
                            <div className="card-body">
                                <h1 className="h4 text-capitalize mb-0 tw-text-primary">
                                    {singleLessonInfo?.lesson_type}
                                </h1>
                            </div>
                        </div>
                    }


                    {singleLessonInfo?.material &&
                        <div className="card">
                            <div className="card-body">
                                <a href={singleLessonInfo?.material?.material_link} className="btn btn-primary btn-block flex-column" target='_blank' rel="noreferrer">
                                    <i className="material-icons">get_app</i> Download Files
                                </a>
                            </div>
                        </div>
                    }

                    <div className="card">
                        <ul className="list-group list-group-fit">
                            <li className="list-group-item">
                                <div className="media align-items-center">
                                    <div className="media-left">
                                        <i className="material-icons text-muted-light">schedule</i>
                                    </div>
                                    <div className="media-body">
                                        {splitTime(singleLessonInfo?.duration)?.hours} <small className="text-muted">hrs</small> &nbsp; {splitTime(singleLessonInfo?.duration)?.mins} <small className="text-muted">min</small>

                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="media align-items-center">
                                    <div className="media-left">
                                        <i className="material-icons text-muted-light">assessment</i>
                                    </div>
                                    <div className="media-body">{singleLessonInfo?.level}</div>
                                </div>
                            </li>
                        </ul>
                    </div>


                </div>
            </div >
        </div>
    )
}

export default CourseSingleLesson
