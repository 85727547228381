import React, { useState, useEffect } from "react";
import OrgChartComponent from "../../OrgChartComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchTree } from "../../Redux/Tree/TreeActions";
import { useParams } from "react-router-dom";
import { absoluteUrl } from "../../_helper";
import { DEFAULT_TREE_LEVEL } from "../../constants/globals";

const Tree = () => {
  const dispatch = useDispatch();
  const { treeData, treeMaxLevel, isLoading, error } =
    useSelector((state) => state.Tree) || {};
  const { loggedInUser } = useSelector((state) => state.Auth);
  const loggedInUserId = loggedInUser?.data?.model?.id;
  const { id } = useParams();
  const partnerId = id ? id : loggedInUserId;

  const [treeLevel, setTreeLevel] = useState(DEFAULT_TREE_LEVEL);

  useEffect(() => {
    if (treeMaxLevel !== null) {
      setTreeLevel(treeMaxLevel >= 3 ? 3 : treeMaxLevel);
    }
  }, [treeMaxLevel]);

  useEffect(() => {
    dispatch(fetchTree(partnerId, treeLevel));
  }, [partnerId, treeLevel, dispatch]);

  const handleTreeLevelChange = (event) => {
    setTreeLevel(Number(event.target.value));
  };

  if (isLoading) return <div>Loading...</div>;
  const treeDataJson = JSON.parse(JSON.stringify(treeData));

  return (
    <div className="tw-px-6 tw-mx-auto">
      <div className="tw-flex tw-w-full tw-items-center tw-gap-4 tw-px-6 tw-py-2 tw-shadow-lg tw-bg-white tw-mb-4">
        <label
          htmlFor="treeLevelSelect"
          className="tw-flex tw-items-center tw-mb-2 tw-text-lg tw-gap-4 tw-min-w-[200px]"
        >
          <img
            src={absoluteUrl("imgs/tree.png")}
            style={{ width: 32 }}
            alt=""
          />
          Tree Level:
        </label>
        {treeMaxLevel > 0 ? (
          <select
            id="treeLevelSelect"
            className="tw-form-control tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-w-full"
            value={treeLevel}
            onChange={handleTreeLevelChange}
          >
            {[...Array(treeMaxLevel)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        ) : (
          <p>No levels available</p>
        )}
      </div>
      {treeDataJson?.id ? (
        <OrgChartComponent datasource={treeDataJson} />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Tree;
