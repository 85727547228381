import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { listSettledClientsApi, listUnsettledClientsApi } from "../../../apis";
import { DATATABLE_CUSTOM_STYLES } from "../../../constants/datatableStyles";
import CustomPagination from "../../../Components/CustomPagination";
import { createColumns } from "../../../helpers";
import Tabs from "../../../Components/Tabs"; // Import Tabs component

const SettlementPartnerList = () => {
  const { id: taswiaId } = useParams(); // Get taswiaId from URL parameters
  const location = useLocation();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(location.search).get("tab") || "unsettled" // Default to "unsettled" tab
  );
  const [searchTerm, setSearchTerm] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page"), 10) || 1
  );
  const [sortKey, setSortKey] = useState(
    new URLSearchParams(location.search).get("sortKey") || ""
  );
  const [sortDirection, setSortDirection] = useState(
    new URLSearchParams(location.search).get("sortDirection") || ""
  );
  const [unsettledData, setUnsettledData] = useState([]); // Separate state for unsettled clients
  const [settledData, setSettledData] = useState([]); // Separate state for settled clients
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchClientsData = async () => {
      setIsLoading(true);
      try {
        const unsettledResponse = await listUnsettledClientsApi(
          taswiaId,
          currentPage
        );
        const settledResponse = await listSettledClientsApi(
          taswiaId,
          currentPage
        );
        setUnsettledData(unsettledResponse.data.data.clients);
        setSettledData(settledResponse.data.data.data.clients);
        setData({
          unsettled: unsettledResponse.data.data,
          settled: settledResponse.data.data.data,
        });
        if (currentTab === "unsettled") {
          setPagination(unsettledResponse.data.paginator);
        } else {
          const { data, ...paginator } = settledResponse.data.data;
          setPagination(paginator);
        }
      } catch (error) {
        console.error("Failed to fetch clients data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientsData();
  }, [taswiaId, currentPage, currentTab]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tab", currentTab);
    if (searchTerm) params.set("search", searchTerm);
    params.set("page", currentPage);
    if (sortKey) params.set("sortKey", sortKey);
    if (sortDirection) params.set("sortDirection", sortDirection);
    navigate({ search: params.toString() });
  }, [currentTab, searchTerm, currentPage, sortKey, sortDirection, navigate]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    setCurrentPage(1);
  };

  const handleFilter = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = (column, direction) => {
    setSortKey(column.sortKey);
    setSortDirection(direction);
    setCurrentPage(1); // Reset to first page on sort change
  };

  const hiddenColumns = ["id", "client_id"]; // Adjust column keys as needed
  const customColumns = () => {
    const columns = [];

    if (currentTab === "settled") {
      columns.push({
        name: "Action",
        selector: (row) => `Settlement - ${taswiaId} - ${row.client_id}`,
        ui: (row) => (
          <Link
            to={`/settlement/${taswiaId}/settled-partners/${row.client_id}`}
          >
            Details
          </Link>
        ),
      });
    } else if (currentTab === "unsettled") {
      columns.push({
        name: "Action",
        selector: (row) => `Settlement - ${taswiaId} - ${row.client_id}`,
        ui: (row) => (
          <Link
            to={`/settlement/${taswiaId}/unsettled-partners/${row.client_id}`}
          >
            Details
          </Link>
        ),
      });
    }

    return columns;
  };

  const columns = useMemo(() => {
    return createColumns(
      currentTab === "unsettled" ? unsettledData : settledData,
      customColumns(),
      hiddenColumns,
      true
    );
  }, [currentTab, unsettledData, settledData]);

  const currentData = currentTab === "unsettled" ? unsettledData : settledData;

  const SETTLEMENT_PARTNERS_DATA_TABS = [
    {
      key: "unsettled",
      label: `Unsettled Partners (${data.unsettled?.total_clients || 0})`,
    },
    {
      key: "settled",
      label: `Settled Partners (${data.settled?.total_clients || 0})`,
    },
  ];
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
      <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">Current Settlement </h4>
      <div className="tw-flex tw-w-full tw-gap-6 tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-rounded-lg tw-bg-gray-300">
        <p className="tw-text-lg">
          <span className="tw-text-primary">End date: </span>{" "}
          {data.unsettled?.current_taswia?.end_date ||
            data.settled?.current_taswia?.end_date}{" "}
        </p>
        <p className="tw-min-w-[100px] tw-text-lg">
          <span className="tw-text-primary"> Created at:</span>{" "}
          {data.unsettled?.current_taswia?.created_at ||
            data.settled?.current_taswia?.created_at}
        </p>
        <p className="tw-min-w-[100px] tw-text-lg">
          <span className="tw-text-primary"> Created by:</span>{" "}
          {data.unsettled?.current_taswia?.created_by_name ||
            data.settled?.current_taswia?.created_by_name}
        </p>
      </div>
      <div className="bg-white flex flex-col mt-4">
        {/* Tabs Component for Navigation */}
        <Tabs
          options={SETTLEMENT_PARTNERS_DATA_TABS}
          onTabClick={handleTabClick}
          currentTab={currentTab}
        />

        <div className="bg-white shadow-lg rounded-lg flex flex-col p-4 gap-2 mb-5">
          <div className="relative">
            <input
              type="text"
              onChange={handleFilter}
              value={searchTerm}
              className="border border-blue-300 rounded-lg p-2 mb-2 col-md-12"
              placeholder="Search by name"
            />
          </div>
          <DataTable
            columns={columns}
            data={currentData}
            customStyles={DATATABLE_CUSTOM_STYLES}
            progressPending={isLoading}
            onSort={handleSortChange}
            sortServer
          />
          <div className="flex justify-center mt-4">
            <CustomPagination
              totalPages={pagination?.last_page}
              currentPage={pagination?.current_page || 1}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlementPartnerList;
