import React from "react";
import { Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import AllMatchedPartners from "./AllMatchedPartners";
import { genericStepDataByKey } from "../../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";

const DirectPartnerField = ({
  values,
  handleBlur,
  setFieldValue,
  debouncedDirectAsyncValidation,
  touched,
  errors,
}) => {
  const dispatch = useDispatch();
  const { allDirectPartnersMatchsSearchKey, showDirectOtherList } = useSelector(
    (state) => state?.AddOrEditPartner?.step6
  );

  const loggedInUserClientId = useSelector(
    (state) => state?.Auth?.loggedInUser?.data?.model?.id
  );

  return (
    <>
      {values.upliner_partner_id && (
        <div className="row">
          <div className="col-md-5 mb-3">
            <label
              className="form-label required-label"
              htmlFor="direct_partner_me_other"
            >
              Direct Partner
            </label>
            <Field
              as="select"
              className={`custom-control custom-select form-control ${
                touched.direct_partner_me_other &&
                errors.direct_partner_me_other
                  ? "custom-invalid-input"
                  : ""
              }`}
              name="direct_partner_me_other"
              onBlur={handleBlur}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("direct_partner_me_other", value);
                setFieldValue("direct_partner_other_value", "");

                dispatch(
                  genericStepDataByKey({
                    keyName: "showDirectOtherList",
                    keyValue: false,
                    stepNumber: 6,
                  })
                );

                if (value !== "other") {
                  setFieldValue("direct_partner_id", value);
                } else {
                  setFieldValue("direct_partner_id", "");
                }
              }}
            >
              <option value="" disabled>
                Select Direct Partner
              </option>
              <option value={loggedInUserClientId}>Me</option>
              <option value="other">Other</option>
            </Field>
            {touched.direct_partner_me_other &&
              values.direct_partner_me_other &&
              errors.direct_partner_me_other && (
                <div className="invalid-feedback">
                  {errors.direct_partner_me_other}
                </div>
              )}
          </div>
          {values?.direct_partner_me_other === "other" && (
            <div className="col-md-5 mb-3">
              <div className="w-100 position-relative">
                <label
                  className="form-label required-label"
                  htmlFor="direct_partner_other_value"
                >
                  Other
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    touched.direct_partner_other_value &&
                    errors.direct_partner_other_value
                      ? "custom-invalid-input"
                      : ""
                  }`}
                  name="direct_partner_other_value"
                  placeholder="search by referral code or partner name"
                  onBlur={handleBlur}
                  value={values.direct_partner_other_value}
                  onChange={(e) => {
                    setFieldValue("direct_partner_other_value", e.target.value);
                    if (e.target.value.length >= 2) {
                      debouncedDirectAsyncValidation(
                        "allDirectPartnersMatchsSearchKey",
                        e.target.value,
                        values.upliner_partner_id // Pass upliner_partner_id here
                      );
                      dispatch(
                        genericStepDataByKey({
                          keyName: "showDirectOtherList",
                          keyValue: true,
                          stepNumber: 6,
                        })
                      );
                    } else {
                      dispatch(
                        genericStepDataByKey({
                          keyName: "showDirectOtherList",
                          keyValue: false,
                          stepNumber: 6,
                        })
                      );
                      dispatch(
                        genericStepDataByKey({
                          keyName: "allDirectPartnersMatchsSearchKey",
                          keyValue: [],
                          stepNumber: 6,
                        })
                      );
                    }
                  }}
                />
                {allDirectPartnersMatchsSearchKey.length > 0 &&
                  showDirectOtherList && (
                    <AllMatchedPartners
                      matchedPartners={allDirectPartnersMatchsSearchKey}
                      onClickHandler={(mp) => {
                        dispatch(
                          genericStepDataByKey({
                            keyName: "showDirectOtherList",
                            keyValue: false,
                            stepNumber: 6,
                          })
                        );
                        dispatch(
                          genericStepDataByKey({
                            keyName: "allDirectPartnersMatchsSearchKey",
                            keyValue: [],
                            stepNumber: 6,
                          })
                        );
                        setFieldValue(
                          "direct_partner_other_value",
                          mp?.referral_code
                        );
                        setFieldValue("direct_partner_id", mp?.id);
                      }}
                    />
                  )}
              </div>
              {touched.direct_partner_other_value &&
                values.upliner_partner_id &&
                errors.direct_partner_other_value && (
                  <div className="invalid-feedback">
                    {errors.direct_partner_other_value}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DirectPartnerField;
