import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPartnerStepInfoAction,
  updateStepData,
} from "../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import Swal from "sweetalert2";
import FormField from "../../Components/Form/FormField";
import OTPVerification from "../../Components/Form/OTPVerification";
import { emailRegex } from "../../constants/globals";

const UpdatePartnerData = () => {
  const { userId: id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step1 } = useSelector((state) => state.AddOrEditPartner);
  const { stepInfo } = step1;

  const [nextStep, setNextStep] = useState(null);
  const [email, setEmail] = useState(stepInfo?.email);
  const [serverErrors, setServerErrors] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const verifyEmail = params.get("verifyEmail");
  const shouldVerifyEmail = !!verifyEmail && stepInfo?.is_email_verified === 0;
  useEffect(() => {
    if (id) {
      dispatch(getCurrentPartnerStepInfoAction(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (stepInfo?.account_creation_status_id === 7) {
      return navigate("/partners-list");
    }
  }, [stepInfo]);

  const initialValues = {
    first_name: stepInfo?.first_name || "",
    second_name: stepInfo?.second_name || "",
    last_name: stepInfo?.last_name || "",
    email: stepInfo?.email || "",
    phone: stepInfo?.phone || "",
    phone_country_code: "+2",
    identification_type: stepInfo?.identification_type || "1",
    identification_number: stepInfo?.identification_number || "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    second_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^(010|011|012|015)\d{8}$/, {
        message:
          "Phone number must be 11 digits and start with 010, 011, 012, or 015",
        excludeEmptyString: true,
      }),
    phone_country_code: Yup.string().required("Country code is required"),
    identification_type: Yup.string().required("Required"),
    identification_number: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(
      updateStepData(
        { ...values, is_done: 1, client_id: id },
        1,
        false,
        (response) => {
          setEmail(values.email);
          dispatch(getCurrentPartnerStepInfoAction(id));
          if (response?.next_step === 2) {
            setNextStep(2);
            Swal.fire({
              title: "Good!",
              text: "Email updated! OTP sent for verification",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Good!",
              text: "Step 1 data updated successfully",
              icon: "success",
            });
            navigate("/partners-list");
          }
        },
        (errorsList) => {
          setServerErrors(errorsList);
        }
      )
    );
  };

  if (nextStep === 2 || shouldVerifyEmail) {
    return (
      <div className="card">
        <div className="card-header">
          <h4 className="card-title mb-0">Verify E-mail</h4>
        </div>
        <OTPVerification
          clientId={id}
          navigate={navigate}
          partnerEmail={email ? email : stepInfo?.email}
          triggerSendOtp={shouldVerifyEmail}
        />
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        {serverErrors && (
          <div className="alert alert-danger">
            {serverErrors.map((item, index) => (
              <div key={index}>
                <p className="!tw-mb-0">{item}</p>
              </div>
            ))}
          </div>
        )}
        <div className="card-header">
          <h4 className="card-title mb-0">Update Step (1): Basic Data</h4>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            values,
            setFieldValue,
          }) => (
            <Form className="d-flex flex-column">
              <div className="row">
                <FormField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.first_name}
                />
                <FormField
                  name="second_name"
                  label="Second Name"
                  placeholder="Second Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.second_name}
                />
                <FormField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.last_name}
                />
                <FormField
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone"
                  type="text"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.phone}
                />
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  value={values.email}
                />
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="identification_type"
                  >
                    Identification Type
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.identification_type && errors.identification_type
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="identification_type"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("identification_type", e.target.value);
                      setFieldValue("identification_number", "");
                    }}
                    disabled={stepInfo?.account_creation_status_id === 6}
                  >
                    <option value="1">National ID</option>
                    <option value="2">Passport</option>
                  </Field>
                  {touched.identification_type &&
                    errors.identification_type && (
                      <div className="invalid-feedback">
                        {errors.identification_type}
                      </div>
                    )}
                </div>
                <FormField
                  name="identification_number"
                  label="Identification Number"
                  placeholder="Identification Number"
                  type="text"
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (values.identification_type === "1") {
                      if (/^\d{0,14}$/.test(value)) {
                        setFieldValue("identification_number", value);
                      }
                    } else {
                      if (/^[a-zA-Z]?\d{0,8}$/.test(value)) {
                        setFieldValue("identification_number", value);
                      }
                    }
                  }}
                  value={values.identification_number}
                />
              </div>
              <button type="submit" className="btn btn-primary ml-auto">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdatePartnerData;
