import React, { useState } from "react";
import PropTypes from "prop-types";

// Your renderStarIcon function
export const renderStarIcon = (index, ratingValue) => {
    if (index < ratingValue) {
        if (ratingValue - index === 0.5) {
            return 'star_half'; // Half-filled star
        }
        return 'star'; // Fully filled star
    } else {
        return 'star_border'; // Empty star
    }
};

const RatingReview = ({
    totalStars = 5,
    currentRating = 0,
    onRatingChange,
    editable = true
}) => {
    const [hoverRating, setHoverRating] = useState(null);

    const handleMouseEnter = (index, event) => {
        if (!editable) return;

        const { width, left } = event.target.getBoundingClientRect();
        const hoverX = event.clientX - left;

        let rating;
        if (hoverX < width / 2) {
            rating = index + 0.5;
        } else {
            rating = index + 1;
        }

        setHoverRating(rating);
    };

    const handleMouseLeave = () => {
        if (editable) setHoverRating(null);
    };

    const handleClick = (index, event) => {
        if (!editable || !onRatingChange) return;

        const { width, left } = event.target.getBoundingClientRect();
        const clickX = event.clientX - left;

        let rating;
        if (clickX < width / 2) {
            rating = index + 0.5;
        } else {
            rating = index + 1;
        }

        onRatingChange(rating);
    };

    const displayedRating = hoverRating !== null ? hoverRating : currentRating;

    return (
        <div className="rating" style={{ display: "flex", gap: "4px" }}>
            {[...Array(totalStars)].map((_, index) => (
                <i
                    key={index}
                    className="material-icons"
                    onMouseEnter={(event) => handleMouseEnter(index, event)}
                    onMouseLeave={handleMouseLeave}
                    onClick={(event) => handleClick(index, event)}
                    style={{ cursor: editable ? "pointer" : "default", color: "#ffcc00" }}
                >
                    {renderStarIcon(index, displayedRating)}
                </i>
            ))}
        </div>
    );
};

RatingReview.propTypes = {
    totalStars: PropTypes.number,         // Total number of stars
    currentRating: PropTypes.number,      // Current rating
    onRatingChange: PropTypes.func,       // Function to handle rating change
    editable: PropTypes.bool              // Whether the rating is editable or not
};

export default RatingReview;
