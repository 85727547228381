import React, { useEffect } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import PartnerStepOne from "./PartnerSteps/PartnerStepOne";
import PartnerSteptwo from "./PartnerSteps/PartnerSteptwo";

import { useDispatch, useSelector } from "react-redux";
import {
  genericResetStepsReducer,
  intialState,
  setCurrnetStep,
} from "../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import PartnerStepThree from "./PartnerSteps/PartnerStepThree";
import PartnerStepFour from "./PartnerSteps/PartnerStepFour";
import PartnerStepFive from "./PartnerSteps/PartnerStepFive";
import { getCurrentPartnerStepInfoAction } from "../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import PartnerStepSix from "./PartnerSteps/PartnerStepSix";
const AddOrEditPartnerIntialState = { ...intialState };

const AddOrEditPartner = () => {
  let { type, userId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state?.AddOrEditPartner);
  const navigate = useNavigate();
  const stepsArray = [
    "Basic Data",
    "Verify e mail",
    "Verify e money",
    "Package Data",
    "Complete Data",
    "Activate Partner",
  ];

  useEffect(() => {
    dispatch(genericResetStepsReducer(AddOrEditPartnerIntialState));
    if (type == "edit" && userId) {
      dispatch(
        getCurrentPartnerStepInfoAction(
          userId,
          () => {},
          () => {
            navigate("/partners-list");
          }
        )
      );
    }
  }, [type]);

  useEffect(() => {
    if (currentStep == 7) {
      navigate("/partners");
    }
  }, [currentStep]);

  let AllSteps = [
    <PartnerStepOne />,
    <PartnerSteptwo />,
    <PartnerStepThree />,
    <PartnerStepFour />,
    <PartnerStepFive />,
    <PartnerStepSix />,
  ];

  return (
    <>
      {type === "add" || type === "edit" ? (
        <>
          <Breadcrumbs
            BreadcrumbItems={[
              { title: "Home", link: "/" },
              { title: "Parnters", link: "/parnters" },
            ]}
          />

          <h1 className="h2 text-capitalize">
            {type === "edit" ? "Complete partner data" : "Add Partner"}
          </h1>

          {/* <button className='btn' onClick={() => { dispatch(setCurrnetStep(currentStep + 1)) }}> next </button> */}
          <div className="row">
            <div className="col-lg-9">
              <div className="text-danger text-right">
                * means this field is required{" "}
              </div>
              <div className="card">{AllSteps[currentStep - 1]}</div>
            </div>

            <div className="col-lg-3">
              <div className="page-nav">
                <div data-perfect-scrollbar="">
                  <div className="page-section pt-lg-32pt">
                    <nav className="nav page-nav__menu">
                      {stepsArray?.length > 0 &&
                        stepsArray.map((el, elIndex) => (
                          <a
                            href="#"
                            className={`disabled nav-link ${
                              elIndex + 1 <= currentStep ? "active" : ""
                            } ${
                              elIndex + 1 == currentStep
                                ? "stepper-currentStep"
                                : ""
                            }`}
                            key={elIndex}
                          >
                            {el}{" "}
                          </a>
                        ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddOrEditPartner;
