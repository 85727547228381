import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  getJobsAction,
  getEducationalLevelsAction,
  saveStepData,
  getCountriesAction,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import { genericStepDataByKey } from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";

const PartnerStepFive = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state?.UI); //ar or en
  const { step1, step5 } = useSelector((state) => state?.AddOrEditPartner);
  const { stepInfo: Step1Info } = step1;

  const { validationObject, educationalLevels, jobs, countries, cities } =
    step5;

  // identification_type (1 or 2)>>
  // 1: identification number , 2 : passport
  const { identification_type } = step1?.stepInfo;

  useEffect(() => {
    dispatch(getEducationalLevelsAction());
    dispatch(getJobsAction());
    dispatch(getCountriesAction());
  }, []);

  // Initial form values
  const initialValues = {
    gender: "",
    dob: "",
    additional_phone: "",
    educational_level_id: "",
    job_category_id: "",

    country_id: "",
    city_id: "",

    front_image: null,
    back_image: null,
    passport_image: null,

    note: "",
  };

  // Validation schema using Yup
  const StepFiveSchema = Yup.object().shape({
    gender: Yup.string("").required("Required"),
    dob: Yup.string()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Date of Birth must be in the format yyyy-MM-dd"
      )
      .required("required"),
    additional_phone: Yup.string()
      .nullable()
      .matches(/^(010|011|012|015)\d{8}$/, {
        message:
          "Phone number must be 11 digits and start with 010, 011, 012, or 015",
        excludeEmptyString: true,
      }),

    educational_level_id: Yup.string("").required("Required"),
    job_category_id: Yup.string("").required("Required"),

    country_id: Yup.string("").required("Required"),
    city_id: Yup.string("").required("Required"),

    front_image:
      identification_type == 1
        ? Yup.mixed()
            .required("Front Image is required")
            .test("fileType", "Invalid file format", function (value) {
              if (!value) return true; // Handle optional file input
              const allowedExtensions = ["pdf", "png", "jpg", "jpeg"];
              const fileExtension = value?.name?.split(".").pop().toLowerCase();
              return allowedExtensions.includes(fileExtension);
            })
            .test(
              "fileSize",
              "File size must be less than 5MB",
              function (value) {
                if (!value) return true; // Handle optional file input
                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                return value?.size <= maxSize;
              }
            )
        : Yup.mixed().nullable(),

    back_image:
      identification_type == 1
        ? Yup.mixed()
            .required("Back Image is required")
            .test("fileType", "Invalid file format", function (value) {
              if (!value) return true; // Handle optional file input
              const allowedExtensions = ["pdf", "png", "jpg", "jpeg"];
              const fileExtension = value?.name?.split(".").pop().toLowerCase();
              return allowedExtensions.includes(fileExtension);
            })
            .test(
              "fileSize",
              "File size must be less than 5MB",
              function (value) {
                if (!value) return true; // Handle optional file input
                const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                return value?.size <= maxSize;
              }
            )
        : Yup.mixed().nullable(),

    passport_image:
      identification_type == 2
        ? Yup.mixed()
            .required("Passport Image is required")
            .test("fileType", "Invalid file format", function (value) {
              if (!value) return true; // Handle optional file input
              const allowedExtensions = [
                "txt",
                "docx",
                "pdf",
                "png",
                "jpg",
                "jpeg",
                "csv",
                "xlsx",
              ];
              const fileExtension = value?.name?.split(".").pop().toLowerCase();
              return allowedExtensions.includes(fileExtension);
            })
        : Yup.mixed().nullable(),
  });

  // Form submit handler
  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("client_id", step1?.stepInfo?.id);
    formData.append("gender", values?.gender);
    formData.append("dob", values?.dob);
    if (values?.additional_phone.length > 0) {
      formData.append("additional_phone", values?.additional_phone);
    }
    formData.append("educational_level_id", values?.educational_level_id);
    formData.append("job_category_id", values?.job_category_id);
    formData.append("country_id", values?.country_id);
    formData.append("city_id", values?.city_id);
    // identification number
    if (identification_type == 1) {
      formData.append("front_image", values?.front_image);
      formData.append("back_image", values?.back_image);
    } else if (identification_type == 2) {
      formData.append("passport_image", values?.passport_image);
    }
    for (var pair of formData.entries()) {
    }
    dispatch(
      saveStepData(formData, 5, true, () => {
        Swal.fire({
          title: "Good!",
          text: "Additional Information was added successfully",
          icon: "success",
        });
      })
    );
  };

  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">Step (5) : Complete Data</h4>
        <h5 className="card-title mb-0">
          Partner Name:{" "}
          <span className="text-muted">
            {Step1Info?.first_name +
              " " +
              Step1Info?.second_name +
              " " +
              Step1Info?.last_name}
          </span>
        </h5>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={StepFiveSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="d-flex flex-column">
              <div className="row">
                {/* =========================================================================================== */}
                {/* GENDER */}
                <div className="col-md-4 mb-3">
                  <label className="form-label required-label" htmlFor="gender">
                    Gender
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.gender && errors.gender
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="gender"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("gender", e.target.value);
                    }}
                  >
                    <option value="" disabled></option>
                    <option value="1">Male</option>
                    <option value="0">Female</option>
                  </Field>

                  {touched.gender && errors.gender && (
                    <div className="invalid-feedback">{errors.gender}</div>
                  )}
                </div>
                {/* DOB */}
                <div className="col-md-4 mb-3 d-flex flex-column">
                  <label
                    className="form-label required-label mr-auto"
                    htmlFor="dob"
                  >
                    Date Of Birth
                  </label>

                  {/* <DatePicker
                                        selected={values.dob}
                                        onChange={(date) => {
                                            if (date != null) {
                                                const formattedDate = format(date, 'yyyy-MM-dd');
                                                setFieldValue('dob', formattedDate);
                                            } else {
                                                setFieldValue('dob', "");
                                            }
                                        }}
                                        onBlur={(e) => handleBlur({ target: { name: 'dob' } })}
                                        dateFormat="yyyy-MM-dd"
                                        className={`form-control ${touched.dob ? (errors.dob ? 'custom-invalid-input' : '') : ''}`}

                                    /> */}
                  <Flatpickr
                    value={values.dob}
                    onChange={([date]) => {
                      if (date != null) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        setFieldValue("dob", formattedDate);
                      } else {
                        setFieldValue("dob", "");
                      }
                    }}
                    onBlur={(e) => handleBlur({ target: { name: "dob" } })}
                    dateFormat="yyyy-MM-dd"
                    options={{ maxDate: new Date() }}
                    className={`form-control ${
                      touched.dob
                        ? errors.dob
                          ? "custom-invalid-input"
                          : ""
                        : ""
                    }`}
                  />
                  {touched.dob && errors.dob && (
                    <div className="invalid-feedback">{errors.dob}</div>
                  )}
                </div>
                {/* Additional Phone */}
                <div className="col-md-4 mb-3">
                  <label className="form-label" htmlFor="additional_phone">
                    Additional Phone
                  </label>
                  <Field
                    type="text"
                    name="additional_phone"
                    className={`form-control ${
                      touched.additional_phone &&
                      (errors.additional_phone ||
                        validationObject[`additional_phone`]?.length > 0)
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    placeholder="Additional Phone"
                    maxLength="11"
                    inputMode="numeric"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // Remove any non-digit characters from the input
                      const sanitizedValue = value.replace(/\D/g, "");
                      // Limit to 6 digits
                      const truncatedValue = sanitizedValue.slice(0, 11);
                      // Update state and form field value
                      setFieldValue(name, truncatedValue);
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.additional_phone && (
                    <>
                      {validationObject[`additional_phone`]?.length > 0 ? (
                        <>
                          {validationObject[`additional_phone`].map(
                            (validationElement, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationElement}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.additional_phone && (
                            <div className="invalid-feedback">
                              {errors.additional_phone}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* Educational Level */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="educational_level_id"
                  >
                    Educational Level
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.educational_level_id &&
                      errors.educational_level_id
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="educational_level_id"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("educational_level_id", e.target.value);
                    }}
                  >
                    <option value="" disabled></option>
                    {educationalLevels?.length > 0 &&
                      educationalLevels?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el[`name_${lang}`]}
                        </option>
                      ))}
                  </Field>

                  {touched.educational_level_id &&
                    errors.educational_level_id && (
                      <div className="invalid-feedback">
                        {errors.educational_level_id}
                      </div>
                    )}
                </div>
                {/* Job Category */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="job_category_id"
                  >
                    Job Category
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.job_category_id && errors.job_category_id
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="job_category_id"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("job_category_id", e.target.value);
                    }}
                  >
                    <option value="" disabled></option>
                    {jobs?.length > 0 &&
                      jobs?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el[`name_${lang}`]}
                        </option>
                      ))}
                    {/* hereeeee */}
                  </Field>

                  {touched.job_category_id && errors.job_category_id && (
                    <div className="invalid-feedback">
                      {errors.job_category_id}
                    </div>
                  )}
                </div>

                {/* Country */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="country_id"
                  >
                    Country
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.country_id && errors.country_id
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="country_id"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("country_id", e.target.value);

                      setFieldValue("city_id", "");

                      dispatch(
                        genericStepDataByKey({
                          keyName: "cities",

                          keyValue: countries
                            .find((el) => el.id == e.target.value)
                            ?.cities?.filter((el) => el.is_active == 1),
                          stepNumber: 5,
                        })
                      );
                    }}
                  >
                    <option value="" disabled></option>
                    {countries?.length > 0 &&
                      countries?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el[`country_name_${lang}`]}
                        </option>
                      ))}
                    {/* hereeeee */}
                  </Field>

                  {touched.country_id && errors.country_id && (
                    <div className="invalid-feedback">{errors.country_id}</div>
                  )}
                </div>
                {/* City */}
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label required-label"
                    htmlFor="city_id"
                  >
                    City
                  </label>
                  <Field
                    as="select"
                    className={`custom-control custom-select form-control ${
                      touched.city_id && errors.city_id
                        ? "custom-invalid-input"
                        : ""
                    }`}
                    name="city_id"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("city_id", e.target.value);
                    }}
                  >
                    <option value="" disabled></option>
                    {cities?.length > 0 &&
                      cities?.map((el) => (
                        <option key={el?.id} value={el?.id}>
                          {el[`city_name_${lang}`]}
                        </option>
                      ))}
                    {/* hereeeee */}
                  </Field>

                  {touched.city_id && errors.city_id && (
                    <div className="invalid-feedback">{errors.city_id}</div>
                  )}
                </div>

                {identification_type == 2 && (
                  <>
                    {/* passport */}
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label required-label"
                        htmlFor="passport_image"
                      >
                        Passport image
                      </label>
                      <div className="">
                        <input
                          type="file"
                          name="passport_image"
                          className={`form-control ${
                            touched.passport_image
                              ? errors.passport_image
                                ? "custom-invalid-input"
                                : ""
                              : ""
                          }`}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setFieldValue("passport_image", file);
                          }}
                          accept=".pdf, .png, .jpg, .jpeg"
                        />
                      </div>

                      <ErrorMessage
                        name="passport_image"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </>
                )}
                {identification_type == 1 && (
                  <>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label required-label"
                        htmlFor="front_image"
                      >
                        front_image
                      </label>
                      <div className="">
                        <input
                          type="file"
                          name="front_image"
                          className={`form-control ${
                            touched.front_image
                              ? errors.front_image
                                ? "custom-invalid-input"
                                : ""
                              : ""
                          }`}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setFieldValue("front_image", file);
                          }}
                          accept=".pdf, .png, .jpg, .jpeg"
                        />
                      </div>

                      <ErrorMessage
                        name="front_image"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        className="form-label required-label"
                        htmlFor="back_image"
                      >
                        back_image
                      </label>
                      <div className="">
                        <input
                          type="file"
                          name="back_image"
                          className={`form-control ${
                            touched.back_image
                              ? errors.back_image
                                ? "custom-invalid-input"
                                : ""
                              : ""
                          }`}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setFieldValue("back_image", file);
                          }}
                          accept=".pdf, .png, .jpg, .jpeg"
                        />
                      </div>

                      <ErrorMessage
                        name="back_image"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </>
                )}

                <div className="col-md-6 mb-3">
                  <label className="form-label" htmlFor="note">
                    note
                  </label>
                  <textarea
                    type="text"
                    name="note"
                    className="form-control"
                    rows="4"
                    value={values.note}
                    onChange={(e) => {
                      setFieldValue("note", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="note"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                {/* =========================================================================================== */}
              </div>
              <button type="submit" className="btn btn-primary ml-auto">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PartnerStepFive;
