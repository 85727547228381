import axios from "axios";

// Generalized function for fetching admin partners
function fetchAdminPartners(
  endpoint,
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return axios.get(`/admin/partners-list/${endpoint}`, {
    params: {
      search: searchTerm,
      page,
      sort_by: sortBy,
      sort_dir: sortDir,
    },
  });
}

// Specific functions using the generalized fetchAdminPartners function
export function fetchAdminUncompletedPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("uncompleted", searchTerm, page, sortBy, sortDir);
}

export function fetchAdminPendingPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("pending", searchTerm, page, sortBy, sortDir);
}

export function fetchAdminAllPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("all", searchTerm, page, sortBy, sortDir);
}

export function fetchAdminPvActivePartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("pv-active", searchTerm, page, sortBy, sortDir);
}

export function fetchAdminDirectPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("direct", searchTerm, page, sortBy, sortDir);
}

export function fetchAdminUplinerPartners(
  searchTerm = "",
  page = 1,
  sortBy,
  sortDir
) {
  return fetchAdminPartners("upliner", searchTerm, page, sortBy, sortDir);
}
