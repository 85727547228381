import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStepErrors,
  genericStepDataByKey,
  setStep2IsDisabled,
  setStep2setCounter,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import { debounce } from "lodash";
import {
  justValidateInput,
  resendOtpAction,
  saveStepData,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import Swal from "sweetalert2";

const PartnerStepTwo = () => {
  const { step1, step2 } = useSelector((state) => state?.AddOrEditPartner);
  const { stepInfo: Step1Info } = step1;
  const { resendOtp: resendOtpInfo, validationObject } = step2;
  const { isDisabled, counter } = resendOtpInfo;

  useEffect(() => {
    dispatch(clearStepErrors(2));
  }, []);

  const reFormatObject = (allFormValues) => {
    let finalObject = {};
    finalObject.otp = allFormValues.otp;
    finalObject.client_id = Step1Info.id;

    return finalObject;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        dispatch(setStep2setCounter(counter - 1));
      }, 1000);
    } else {
      dispatch(setStep2IsDisabled(false));
    }

    return () => {
      clearInterval(timer);
    };
  }, [counter, dispatch]);

  useEffect(() => {
    dispatch(setStep2IsDisabled(true));
    dispatch(setStep2setCounter(60));
  }, []);

  const onSuccessForResendOTP = () => {
    // Swal.fire({
    //     title: "Good!",
    //     text: "please check your Email",
    //     icon: "success"
    // });
  };

  const resendOTPHandler = () => {
    // Aded By Marwan to be shown when user click on resend OTP
    Swal.fire({
      title: "Good!",
      text: "please check your Email",
      icon: "success",
    });
    // here will call resend OTP
    if (!isDisabled) {
      dispatch(resendOtpAction(Step1Info.id, onSuccessForResendOTP));
    }
    dispatch(setStep2IsDisabled(true));
    dispatch(setStep2setCounter(60));
  };

  const stepTwoInitialData = {
    otp: "",
  };

  const SteptwoSchema = Yup.object().shape({
    otp: Yup.string()
      .min(2, "Too Short!")
      .max(6, "Too Long!")
      .required("Required"),
  });

  const handleOTPChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    // Remove any non-digit characters from the input
    const sanitizedValue = value.replace(/\D/g, "");
    // Limit to 6 digits
    const truncatedValue = sanitizedValue.slice(0, 6);
    // Update state and form field value
    setFieldValue(name, truncatedValue);
  };
  // Form submit handler
  const handleSubmit = (values) => {
    if (Object.keys(validationObject).length === 0) {
      dispatch(
        saveStepData({ ...reFormatObject(values) }, 2, false, () => {
          Swal.fire({
            title: "Good!",
            text: "Email Verified Successfully",
            icon: "success",
          });
        })
      );
    }
  };

  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className="card-title mb-0">Step (2) : Verify E-mail</h4>
        <h5 className="card-title mb-0">
          Partner Name:{" "}
          <span className="text-muted">
            {Step1Info?.first_name +
              " " +
              Step1Info?.second_name +
              " " +
              Step1Info?.last_name}
          </span>
        </h5>
      </div>
      <div className="card-body">
        <Formik
          initialValues={stepTwoInitialData}
          validationSchema={SteptwoSchema}
          enableReinitialize // To reinitialize stepOneInitialData >> if exist
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleBlur,
            values,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="d-flex flex-column">
              <div className="form-group row mt-3">
                <div className="col-sm-3">
                  <label className="col-form-label form-label">
                    Partner Email:
                  </label>
                </div>
                <div className="col-sm-6 col-md-4">
                  <input
                    type="text"
                    className={`form-control`}
                    value={Step1Info?.email}
                    disabled
                  />
                </div>
              </div>

              {/*OTP */}
              <div className="form-group row mt-3">
                <div className="col-sm-3">
                  <label
                    htmlFor="OTP"
                    className="col-form-label form-label required-label"
                  >
                    OTP:
                  </label>
                </div>
                <div className="col-sm-6 col-md-4">
                  <input
                    type="text" // Use type="text" for better control over input
                    id="otp"
                    name="otp"
                    className={`form-control hidden-controls-number ${
                      touched.otp
                        ? errors.otp || validationObject?.length > 0
                          ? "custom-invalid-input"
                          : ""
                        : ""
                    }`}
                    placeholder="OTP"
                    maxLength="6" // Use maxLength instead of maxlength (case-sensitive attribute names in JSX)
                    onChange={(e) => {
                      handleOTPChange(e, setFieldValue);
                      dispatch(
                        genericStepDataByKey({
                          keyName: "validationObject",
                          keyValue: {},
                          stepNumber: 2,
                        })
                      );
                    }}
                    onBlur={handleBlur}
                    value={values.otp}
                    inputMode="numeric"
                    style={{
                      WebkitAppearance: "none",
                      MozAppearance: "textfield",
                    }}
                  />

                  {isDisabled ? (
                    <div
                      className="d-block text-right"
                      style={{ fontSize: "12px" }}
                    >{`Wait ${counter} seconds`}</div>
                  ) : (
                    <div>
                      {/* <small className="text-black-50 text-uppercase mr-2">The OTP code is sent to your mail, if not,</small> */}
                      <button
                        className="btn d-block text-warning text-right p-0 mr-0"
                        style={{ width: "100%", fontSize: "12px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!isDisabled) {
                            resendOTPHandler();
                          }
                        }}
                        disabled={isDisabled}
                      >
                        Re-send OTP Code
                      </button>
                    </div>
                  )}

                  {touched.otp && (
                    <>
                      {Object.keys(validationObject)?.length > 0 ? (
                        <>
                          {Object.keys(validationObject)?.map(
                            (validationKey, validationElementIndex) => (
                              <div
                                className="invalid-feedback"
                                key={validationElementIndex}
                              >
                                {validationObject[validationKey]}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {errors.otp && (
                            <div className="invalid-feedback">{errors.otp}</div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* <div className="form-group row offset-sm-3">
                                <div className="col-sm-8">
                                    <div className="d-flex align-items-center">
                                        
                                        {isDisabled ?
                                            `Wait ${counter} seconds` :
                                            <small className="text-black-50 text-uppercase mr-2">The OTP code is sent to your mail, if not,</small>
                                        }
                                        
                                    </div>
                                </div>
                            </div> */}

              <button type="submit" className="btn btn-primary ml-auto">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PartnerStepTwo;
