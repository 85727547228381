import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfileSection from "../../../Components/ProfileSection";
import { confirmTaswiaApi, viewClientToSettleApi } from "../../../apis";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SettlementTableSection from "../../../Components/SettlementTableSection";
import Swal from "sweetalert2";
import * as Yup from "yup";
import BackBtn from "../../../Components/BackBtn";

const UnsettledPartner = () => {
  const [partnerData, setPartnerData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: taswiaId, partnerId } = useParams();
  const initialValues = {
    confirmProfile: false,
  };
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await confirmTaswiaApi(taswiaId, partnerId);
      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: "Settlement confirmed successfully.",
          icon: "success",
        });

        navigate(`/settlement/${taswiaId}/partner-list`);
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.message.join(", "),
          icon: "error",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message.join(", ") || error.message;
      Swal.fire({
        title: "Error",
        text: `Failed to create settlement: ${errorMessage}`,
        icon: "error",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    confirmProfile: Yup.boolean().oneOf(
      [true],
      "You must confirm that the profile is correct."
    ),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await viewClientToSettleApi(taswiaId, partnerId);
        setPartnerData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(
          error.response.data.message || "An error occurred while fetching data"
        );
        setLoading(false);
      }
    };
    fetchData();
  }, [taswiaId, partnerId]);

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="alert alert-danger">{error}</div>;

  if (!partnerData) return <div>No partner data available</div>;

  const { partner_image, wallet, ...filteredPartnerData } =
    partnerData.partner_details;
  const partnerDetails = {
    ...filteredPartnerData,
    ...wallet,
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-justify-between">
        <h4 className="h4 mt-3 tw-mb-5 card-title mb-4">
          Unsettled Partner Profile
        </h4>
        <BackBtn
          text={"Back To List"}
          link={`/settlement/${taswiaId}/partner-list`}
        />
      </div>

      <ProfileSection
        title="Partner Profile"
        data={partnerDetails}
        img={partner_image}
        link={{
          url: `/profile/${partnerId}`,
          title: "Go to profile",
        }}
      />

      <ProfileSection
        title="Current Collection"
        data={partnerData.partner_current_collection}
        columns={3}
        link={{
          url: `/settlement/direct-pv-history/${taswiaId}/${partnerId}`,
          title: "PV/Direct History",
        }}
      />

      <SettlementTableSection data={partnerData.expected_earning_from_pv} />

      <div className="tw-container tw-mx-auto tw-p-5 tw-bg-white tw-rounded-[10px] tw-shadow-md">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <div className="form-check mb-3">
                <Field
                  type="checkbox"
                  id="confirmProfile"
                  name="confirmProfile"
                  className="form-check-input"
                />
                <label
                  className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                  htmlFor="confirmProfile"
                >
                  I confirm that I reviewed this profile and it's right.
                </label>
                <ErrorMessage
                  name="confirmProfile"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="tw-flex tw-w-full tw-justify-end">
                <button type="submit" className="primary-btn">
                  Confirm Settlement
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UnsettledPartner;
