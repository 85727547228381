import React, { useEffect, useState } from "react"; // Import useState
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyWalletBalanceAction,
  getPackagesAction,
  saveStepData,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerActions";
import Step4PackageCard from "./Step4/Step4PackageCard";
import SelectedPackageCourse from "./Step4/SelectedPackageCourse";
import {
  clearStepErrors,
  setStep4selectedPackage,
  setStep4selectedPackageFullObject,
  setStepErrors,
  turnOnStep4isSubmittedFlag,
} from "../../../Redux/AddOrEditPartner/AddOrEditPartnerSlice";
import Swal from "sweetalert2";

const PartnerStepFour = () => {
  const { step1, step4 } = useSelector((state) => state?.AddOrEditPartner);
  const { stepInfo: Step1Info } = step1;
  const { lang } = useSelector((state) => state?.UI); //ar or en
  const {
    validationObject,
    AllPackages,
    myWalletBalance,
    selectedPackage,
    selectedPackageFullObject,
    isSubmittedFlag,
  } = step4;
  const dispatch = useDispatch();

  const [isConfirmed, setIsConfirmed] = useState(false); // Add this line for managing the checkbox state

  useEffect(() => {
    dispatch(setStep4selectedPackage(""));
    dispatch(setStep4selectedPackageFullObject({}));

    dispatch(getPackagesAction());
    dispatch(getMyWalletBalanceAction());
  }, []);

  const validateRequiredInput = () => {
    if (!selectedPackage) {
      dispatch(
        setStepErrors({
          errorsList: { package_validation: ["this Field is Required"] },
          stepNumber: 4,
        })
      );
    } else {
      dispatch(clearStepErrors(4));
    }
  };

  useEffect(() => {
    validateRequiredInput();
  }, [selectedPackage]);

  const handleSubmit = () => {
    if (selectedPackage && isConfirmed) {
      // Check if checkbox is confirmed before submitting
      dispatch(
        saveStepData(
          {
            client_id: Step1Info?.id,
            package_id: selectedPackage,
          },
          4,
          false,
          () => {
            Swal.fire({
              title: "Good!",
              text: "Package selected Successfully",
              icon: "success",
            });
          }
        )
      );
    } else {
      if (!isConfirmed) {
        Swal.fire({
          title: "Error",
          text: "You must confirm your selection",
          icon: "error",
        });
      }
      dispatch(turnOnStep4isSubmittedFlag());
    }
  };

  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between">
        <h3 className="card-title mb-0">Step (4): Package Data</h3>
        <h5 className="card-title mb-0">
          Partner Name:{" "}
          <span className="text-muted">
            {Step1Info?.first_name +
              " " +
              Step1Info?.second_name +
              " " +
              Step1Info?.last_name}
          </span>
        </h5>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-8">
            <h3 className="title">Partner Package</h3>
          </div>

          <div className="col-md-4 d-flex flex-column text-right">
            <div className="media-right text-right d-flex align-items-center justify-content-end">
              <span className="text-black-50 mr-1">Current Wallet:</span>
              <span className="mb-0">{myWalletBalance?.balance} E.M</span>
            </div>
            {selectedPackage && (
              <div className="media-right text-center d-flex align-items-center justify-content-end">
                <span className="text-black-50 mr-1">Updated Wallet:</span>
                <span className="mb-0">
                  {myWalletBalance?.balance -
                    (selectedPackageFullObject?.total_price || 0)}{" "}
                  E.M
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="row justify-content-center mt-2 row-cols-2 row-cols-md-4 g-2">
          {AllPackages?.length > 0 &&
            AllPackages?.map((packageDetails, packageDetailsIndex) => (
              <div className="col" key={packageDetailsIndex}>
                <Step4PackageCard packageDetails={packageDetails} />
              </div>
            ))}
        </div>

        {selectedPackageFullObject?.courses?.length > 0 && (
          <div className="row mt-5">
            <div className="col-md-12 mx-auto">
              <h3 className="title">Package Courses</h3>
              <SelectedPackageCourse />
            </div>
          </div>
        )}

        {/* Add the checkbox here */}
        <div className="form-check my-4 tw-flex tw-items-end tw-w-full ">
          <input
            type="checkbox"
            className="form-check-input tw-h-5 tw-w-5"
            id="confirmCheckbox"
            checked={isConfirmed}
            onChange={() => setIsConfirmed(!isConfirmed)}
          />
          <label className="form-check-label form-check-label !tw-m-0 tw-ps-4 tw-w-full" htmlFor="confirmCheckbox">
            I confirm that the selected package is my preferred choice, and I am
            aware that I can't change this selection later.
          </label>
        </div>

        {isSubmittedFlag && validationObject[`package_id`]?.length > 0 && (
          <>
            {validationObject[`package_id`].map(
              (validationElement, validationElementIndex) => (
                <div className="invalid-feedback" key={validationElementIndex}>
                  {validationElement}
                </div>
              )
            )}
          </>
        )}

        <div className="d-flex w-100">
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-primary ml-auto mt-5"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default PartnerStepFour;
