import { createSlice } from "@reduxjs/toolkit";

const intialState = {
  loading: false,
  // we will add 'ar'
  lang: 'en',
  sidebarCollapsed: false,
};


export const UISlice = createSlice({
  name: "UI",
  initialState: intialState,
  reducers: {
    openLoader: (state, action) => {
      state.loading = true;
    },

    closeLoader: (state, action) => {
      state.loading = false;
    },

    changeLang: (state, action) => {
      state.lang = action.payload;
    },

    toggleSidebarCollapse: (state, action) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
  },
});
export const { openLoader, closeLoader, toggleSidebarCollapse } = UISlice.actions;
